import {AiOutlineSearch} from "react-icons/ai";
// @ts-ignore
import {Modal} from "../modal/Modal";
import {SearchUserCard} from "../search-user-card/SearchUserCard";
import React, {useState} from "react";
// @ts-ignore
import {searchForUser} from "../../service/UserService";
import {observer} from "mobx-react-lite";

type Element = {
    name: string;
    profilePicture: string;
    id: string
}

const SearchUser = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [usersFound, setUsersFound] = useState([])
    const [search, setSearch] = useState("")


    const handleCloseModal = () => {
        setSearch('')
        setIsModalOpen(false);
    };

    const handleSearch = async () => {
        const response = await searchForUser(search)
        if(response.ok){
            const data = await response.json()
            setUsersFound(data)
            setIsModalOpen(true)
        }
    }

    const handleSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value)
    }

    const checkForEnterInSearchField = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            await handleSearch()
        }
    }

    return(
        <div>
            <div className="add-friend-container">
                <div className="add-user-icon-and-input">
                    <input onKeyDown={checkForEnterInSearchField} onChange={handleSearchField} value={search} className="search-user-input" type="text" placeholder="Search for user..."/>
                    <div className="search-user-icon-container">
                        <span onClick={handleSearch} className="material-symbols-outlined"><AiOutlineSearch/></span>
                    </div>
                </div>
            </div>
            <div className="modal-container">
                {/*<button onClick={handleOpenModal}>Open Modal</button>*/}
                <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                    {usersFound.map((element: Element, index: number) => {
                        return(
                            <SearchUserCard key={index} name={element.name} image={element.profilePicture} id={element.id}/>
                        )
                    })}
                </Modal>
            </div>
        </div>
    )
}
export default observer(SearchUser)