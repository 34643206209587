import './postReview.css'
import {Rater} from "../rater/Rater";
import {reviewStore} from "../../stores/ReviewStore";
import {restaurantTypes} from "../../resources/RestaurantTypes";
import {useNavigate} from "react-router-dom";
import {deleteReview, postReview} from "../../service/ReviewService";
import {editReview} from "../../service/ReviewService";
import {myStore} from "../../stores/MyStore";
import {observer} from "mobx-react-lite";
import {AiOutlineStar} from 'react-icons/ai'
import {MdOutlinePaid} from 'react-icons/md'
import ImageSelector from "../../hooks/ImageSelector/ImageSelector";
import {BiImageAdd} from 'react-icons/bi'
import {NavigationButtons} from "../NavigationButtons/NavigationButtons";
import {nanoid} from "nanoid";
import {Button} from "../Button/Button";
import React, {FormEvent} from "react";

const PostReview = () => {

    const navigate = useNavigate()

    function handleRestaurantName(event: React.ChangeEvent<HTMLInputElement>) {
        reviewStore.setRestaurantName(event.target.value)
        //restaurantReview.restaurantName = event.target.value
    }
    function handleAddress(event: React.ChangeEvent<HTMLInputElement>){
        reviewStore.address = event.target.value
    }
    function handleCountry(event: React.ChangeEvent<HTMLInputElement>){
        reviewStore.country = event.target.value
    }
    function handleCity(event: React.ChangeEvent<HTMLInputElement>){
        reviewStore.city = event.target.value
    }
    const handleRater = (rating: number) => {
        reviewStore.rating = rating
    }
    const handlePriceRange = (priceRange: number) => {
        reviewStore.priceRange = priceRange
    }
    const handleHeader = (event: React.ChangeEvent<HTMLInputElement>) => {
        reviewStore.header = event.target.value
    }
    const handleReview = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        reviewStore.review = event.target.value
    }
    const handleSelector = (event: React.ChangeEvent<HTMLSelectElement>) => {
        reviewStore.type = event.target.value
    }
    const post = async (event: FormEvent) => {
        event.preventDefault()
        const response = await postReview()
        if(response && response.ok) {
            const data = await response.json()
            myStore.restaurantReviews.unshift(data)
            myStore.posts.unshift(data)
            reviewStore.clearStore()
        }
        navigate(-1)
    }

    const edit = async (event: FormEvent) => {
        event.preventDefault()
        const response = await editReview()
        reviewStore.clearStore()
        reviewStore.editingReview = false

        if(response && response.ok){
            const data = await response.json()
            //restaurantReview.populateStore(data.content)
            const reviewToFind = data._id
            const foundPostIndex = myStore.posts.findIndex(post => post._id === reviewToFind);
            if (foundPostIndex !== -1) {
                // Found the post, update its properties
                const foundPost = myStore.posts[foundPostIndex];
                foundPost.content = data.content; // Assuming "data.content" contains the updated post data
            } else {
                console.log("Post not found");
            }
        }
        navigate(-1)
    }

    const handleFileSelector = async (images: any[]) => {
        reviewStore.addImages(images)
    }

    const callbackNavigateButton = () => {
        reviewStore.clearStore();
        reviewStore._editingReview = false;
        navigate(-1)
    }

    const handleDeleteReview = async (event: React.MouseEvent) => {
        event.preventDefault()
        const response = await deleteReview()
        if(response && response.ok){
            await myStore.deleteUserPost(reviewStore._id)
        }
        navigate(-1)
    }

    return(
        <div className="post-review-container">
            <div className="navigation-button-post-review">
                <NavigationButtons type="back" callbackBack={callbackNavigateButton} size="32px"/>
            </div>
            <form className="post-review" onSubmit={reviewStore.editingReview ? edit : post}>
                <label>Create Restaurant Review</label>
                <input onChange={handleRestaurantName} type="text" placeholder="Restaurant Name" value={reviewStore.restaurantName}></input>
                <input onChange={handleAddress} type="text" placeholder="Address" value={reviewStore.address}></input>
                <input onChange={handleCountry} type="text" placeholder="Country" value={reviewStore.country}></input>
                <input onChange={handleCity} type="text" placeholder="City" value={reviewStore.city}></input>
                <select onChange={handleSelector} className="drop-down-menu" value={reviewStore.type}>
                    <option value="">Select type</option>
                    {restaurantTypes.sort().map((element, index) => {
                        return(
                            <option key={index}>{element}</option>
                        )
                    })}
                    <option>Others</option>
                </select>
                <div className="rater-wrapper">
                    <div className="rater-container">
                        <Rater symbol={<AiOutlineStar/>} elements={5} activeColor="#ffcc00" notActiveColor="#4d4d4d" size="2rem" callback={handleRater}/>
                        <p className="rater-label">Food and service rating</p>
                    </div>
                    <div className="rater-container">
                        <Rater symbol={<MdOutlinePaid/>} elements={5} activeColor="#ffcc00" notActiveColor="#4d4d4d" size="2rem" callback={handlePriceRange}/>
                        <p className="rater-label">Price range</p>
                    </div>
                </div>
                <input onChange={handleHeader} type="text" placeholder="Header (optional)" value={reviewStore.header}></input>
                <textarea onChange={handleReview} placeholder="Write your review here" value={reviewStore.review}/>
                <div className="post-review-image-selector-container">
                    <ImageSelector callback={handleFileSelector} icon={<BiImageAdd className="post-review-image-selector"/>} multiple={true}/>
                </div>
                <div className="create-post-review-images-container">
                    {reviewStore.images.length > 0
                        ?
                        reviewStore.images.map((image, index) => {
                            return(
                                <div className="create-post-review-image-container" key={nanoid()}>
                                    <img key={index} src={image} alt="" className="image-fit"/>
                                </div>
                            )
                        })
                        :
                        <div></div>
                    }
                </div>
                {reviewStore.editingReview ? <Button color="red" fontColor="white" callback={handleDeleteReview} text="Delete Review" width="35vw" margin="16px 0 0 0"/> : <div></div>}
                <button className="submit-review-button">Post</button>
            </form>
        </div>
    )
}
export default observer(PostReview)