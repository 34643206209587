import { LayoutHorizontal } from "../../layouts/LayoutHorizontal/LayoutHorizontal"
import { PopularRecipeModule } from "../../containers/ReviewBrowsingModule/PopularRecipeModule"
import { RecipeCategoryModule } from "../../containers/recipeCategory/RecipeCategoryModule"
import { isExpired } from "react-jwt"
import { NavBar } from "../../components/NavBar/NavBar"
import { myStore } from "../../stores/MyStore"

export const BrowseTwo = () => {

    const isTokenExpired = isExpired(myStore.accessToken)

    return (
        <LayoutHorizontal navBar={isTokenExpired && (<div></div> || <NavBar/>)}>
            {{
                top: <RecipeCategoryModule />,
                bottom: <PopularRecipeModule />
            }}
        </LayoutHorizontal>
    )
}