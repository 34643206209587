//import {user} from "../../stores/UserStore";
//import defaultProfilePicture from '../../resources/default_profilepicture.png'
import "./profile-picture.css"
//import UploadImageButton from "../../hooks/upload_image_button/UploadImageButton";
import {observer} from "mobx-react-lite";
//import {endpoints} from "../../resources/endpoints";

type Props = {
    src: string,
    size: string,
    borderWidth?: string
}

const ProfilePicture = ({src, size, borderWidth}: Props) => {

    const defaultBorderWidth = "3px";

    return(
        <div className="profile-picture-container">
            <img 
                className="profile-picture" 
                src={src} 
                alt={"Profile Picture"} 
                style={{
                    width: size, 
                    height: size,
                    border: `${borderWidth || defaultBorderWidth} solid #FFFFFF`
                }}
            />
        </div>
    )
}
export default observer(ProfilePicture)