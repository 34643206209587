import React, {CSSProperties} from 'react';
import './iconButton.css'

type Props = {
    icon: React.ReactNode
    callback: (event: React.MouseEvent<HTMLButtonElement>) => void
    id: string
    styles: CSSProperties
    text: string
}

export const IconButton = ({callback, id, styles, icon, text}: Props) => {

    return(

        <button type='button' id={id} onClick={callback} className="icon-button" style={styles}>
            <div className="icon-button-icon-container" >
                {icon}
            </div>
            <p className="button-text">{text}</p>
        </button>
    )
}