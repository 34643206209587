import {myStore} from "../stores/MyStore";
import {endpoints} from "../resources/endpoints";

export const vote = async (vote: number, postId: string) => {

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')
    headers.append("token", myStore.accessToken)

    const url = endpoints["vote"]

    return await fetch(url, {
        headers: headers,
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify( {
            postId: postId,
            vote: vote
        }),
    })
}
