import React from "react"
import "./Button.css"

type Props = {
    color: string,
    fontColor: string,
    width: string,
    margin: string
    text: string
    callback: (event: React.MouseEvent<HTMLButtonElement>) => void // Define the callback prop as a function
}
export const Button = ({color, fontColor, width, margin, text, callback}: Props) => {

    return(
        <div>
            <button
                className="custom-button"
                style={{backgroundColor: color, color: fontColor, width: width, margin: margin}}
                onClick={callback}>{text}
            </button>
        </div>
    )
}