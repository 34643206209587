import { makeAutoObservable } from "mobx"
import {Recipe, Review} from "../global.types";

/* type Feed = {
    feed: Review | Recipe
}
 */
class FeedStore {

    feed: (Review | Recipe) [] = []

    constructor() {
        makeAutoObservable(this,
            {},
            {autoBind: true}//For non-arrow-functions bind
        )
        this.feed = []
    }

    addFeed(feed: Review | Recipe){
        this.feed.push(feed)
    }

    clearFeed(){
        this.feed = []
    }
}
export const feed = new FeedStore()