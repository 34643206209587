import {ReactNode, useRef} from "react";
import {observer} from "mobx-react-lite";
import {AiOutlineCamera} from 'react-icons/ai'
import React from "react";

type Props = {
    icon: ReactNode
    multiple: boolean
    callback: (selectedFilePreviews: string[]) => void
}
const ImageSelector = ({icon, multiple, callback}: Props) => {

    //const {icon, multiple} = props

    const inputFile = useRef<HTMLInputElement | null>(null);
    //const [selectedFiles, setSelectedFiles] = useState([]);

    const styles = {
        "fontSize":"2vw",
    }

    const acceptedFileTypes = ".jpeg, .jpg, .png"

    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {

        const files = event.target.files;

        if (files && files.length > 0) {
            // Images selected
/*                        console.log("Files selected:", files);*/
            //const fileNames = Array.from(files).map(file => file.name);
            const selectedFilePreviews = await Promise.all( Array.from(files).map(file => URL.createObjectURL(file)));
            selectedFilePreviews.forEach(image => console.log(image))
/*            setSelectedFiles(selectedFilePreviews);
                        console.log("SetSelected:", selectedFilePreviews.flat());*/
            callback(selectedFilePreviews)
            return;
        } else {
            // User canceled the selection
            console.log("No files selected.");
            //setSelectedFiles([]);
            return []
        }
    };

    const handleImageSelect = () => {
        if (inputFile.current) {
            inputFile.current.click();
        }
    }

    return(
        <div className="edit-profile-picture-button" onClick={handleImageSelect} data-testid="image-selector">
            {!multiple
                ?
                <input className="file-upload" onChange={ handleFileSelect} type='file' id='file' name="file" ref={inputFile} style={{display: 'none'}} accept={acceptedFileTypes}/>
                :
                <input className="file-upload" onChange={handleFileSelect} type='file' id='file' name="file" ref={inputFile} style={{display: 'none'}} accept={acceptedFileTypes} multiple/>
            }
            {icon ? icon : <AiOutlineCamera style={styles}/>}
        </div>
    )
}
export default observer(ImageSelector)