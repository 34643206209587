import { observer } from "mobx-react-lite"
import { ReactNode, useState } from "react"
import './NewRater.css'

type Props = {
    activeSymbol: ReactNode,
    notActiveSymbol: ReactNode,
    elements: number
    onClicks: (element: number) => void
    size: string
    activeColor: string
    notActiveColor: string
}

const NewRater = ({activeSymbol, notActiveSymbol, elements, size, notActiveColor, activeColor, onClicks}: Props) => {

    const [rating, setRating] = useState(0)

    let rater = []

    for(let i = 0 ; i < elements; i++) {
        i < rating ? rater.push(<div style={{color:activeColor}}> {activeSymbol} </div>) : rater.push(<div style={{color:notActiveColor}}>{notActiveSymbol} </div>)
    }

    const handleClick = (elementNumber: number) => {
        setRating(elementNumber + 1)
        onClicks(elementNumber + 1)
    }

    return(
        <div className="update-rater-container">
        {rater.map((symbol, index) => {
            return(
                <div className="update-rater-elements" key={index} onClick={() => handleClick(index)} style={{fontSize: size}}>
                    {symbol}
                </div>
            )
        })}
       </div>
    )
}
export default observer(NewRater)