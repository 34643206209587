import '../../App.css'
import './Browsing.css'
import BrowsingStream from "../../containers/PublicFeed/PublicFeed";
import LayoutThreeColumns from "../../layouts/LayoutThreeColumns/LayoutThreeColumns";
import { BrowseFilter } from '../../containers/browseFilter/BrowseFilter';

export const Browsing = () => {
    return(
        <LayoutThreeColumns>
            {{
                left: <BrowseFilter/>,
                middle: <BrowsingStream/>,
                right: <div></div>
            }}
        </LayoutThreeColumns>
    )
}
