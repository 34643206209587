import {endpoints} from "../resources/endpoints";
import {recipeStore} from "../stores/RecipeStore";
import {myStore} from "../stores/MyStore";


function blobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                const base64String = (reader.result as string).split(',')[1];
                resolve(base64String);
            } else {
                // Handle the case where reader.result is null
                reject(new Error("Failed to read the file."));
            }
        };
        reader.onerror = error => {
            reject(error);
        };
        reader.readAsDataURL(blob);
    });
}

async function fetchBlobFromURL(blobURL: string) {
    try {
        const response = await fetch(blobURL);
        return await response.blob();
    } catch (error) {
        console.error("Error fetching Blob:", error);
        return null;
    }
}


export const postRecipe = async () => {

    const headers = new Headers()
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('token', myStore.accessToken);
    headers.append("post_type",'recipe');

    const url = endpoints['post-recipe'];

    const modifiedSteps = await Promise.all(
        recipeStore.steps.map(async step => {
            const stepWithoutImages = { ...step };

            if (step.stepImages && step.stepImages.length > 0) {
                const base64Images: unknown[] = await Promise.all(
                    step.stepImages.map(async imageBlobURL => {
                        const blob = await fetchBlobFromURL(imageBlobURL);
                        if (blob) {
                            return await blobToBase64(blob);
                        }
                        return null;
                    })
                );
                stepWithoutImages.stepImages = base64Images.filter(base64Image => base64Image !== null) as string[];
            }
            return stepWithoutImages;
        })
    );

    const headerImages = await Promise.all(
        recipeStore._headerImage.map(async image => {
            const blob = await fetchBlobFromURL(image)
            if(blob) {
                return await blobToBase64(blob)
            }
            else{
                console.error("Error handling images")
            }
        })
    )

    const ingredientsWithContent = recipeStore._ingredients.filter(ingredient => ingredient.ingredient && ingredient.unit)

    const modifiedBody = {
        header: recipeStore.header,
        description: recipeStore.description,
        persons: parseInt(recipeStore.persons),
        difficulty: recipeStore.difficulty,
        category: recipeStore.category,
        steps: modifiedSteps,
        headerImages: headerImages,
        ingredients: ingredientsWithContent,
    };

    return await fetch(url, {
        headers: headers,
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(modifiedBody)
    });
}

export const editRecipe = async () => {

    const url = endpoints["edit-recipe"]//"http://localhost:8080/auth/reviews"
    let headers = new Headers();
    headers.append("post_id",recipeStore.id)
    headers.append("token",myStore.accessToken)
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    try{
        return await fetch(url, {
            headers: headers,
            mode: 'cors',
            method: 'PUT',
            body: JSON.stringify(recipeStore.toJSON())
        })
    }catch (e) {
        console.error(e)
        throw e; // Propagate the error by rethrowing it
    }
}

export const deleteRecipe = async () => {

    const url = endpoints["delete-recipe"]

    let headers = new Headers();
    headers.append("post_id",recipeStore.id)
    headers.append("token",myStore.accessToken)
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    try{
        return await fetch(url, {
            headers: headers,
            mode: 'cors',
            method: 'DELETE',
        })

    }catch (e) {
        console.error(e)
        throw e; // Propagate the error by rethrowing it
    }
}

export const getRecipesFromCategory = async (category: string) => {
    
    const endpoint = endpoints["get-recipes-from-category"]

    if(endpoint){
        const url = endpoint.replace('{category}', category);
        let headers = new Headers();
/*         headers.append("post_id",recipeStore.id)
        headers.append("token",user.accessToken) */
        headers.append('Content-Type', 'application/json')
        headers.append('Accept', 'application/json')

        try{
            return await fetch(url, {
                headers: headers,
                mode: 'cors',
                method: 'GET',
            })
    
        }catch (e) {
            console.error(e)
            throw e; // Propagate the error by rethrowing it
        }
    }
    else{
        return null
    }
}

export const getRecipeFromRecipeId = async (id: string) => {

    let endpoint: string = "" 
    let headers = new Headers();
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    if(myStore.accessToken){
        endpoint = endpoints["get-recipe-from-recipe-id-when-signed-in"]
        headers.append("token", myStore.accessToken)
    }
    else{
        endpoint = endpoints["get-recipe-from-recipe-id-when-not-signed-in"]
    }
    
    const url = endpoint.replace('{recipeId}', id);



    try{
        return await fetch(url, {
            headers: headers,
            mode: 'cors',
            method: 'GET',
        })

    }catch (e) {
        console.error(e)
        throw e; // Propagate the error by rethrowing it
    }
}

export const getNewestRecipe = async () => {
    const endpoint = endpoints["get-newest-recipes"];

  if(endpoint){
    let headers = new Headers();
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    try{
      return await fetch(endpoint, {
        headers: headers,
        mode: 'cors',
        method: 'GET',
      })

    }catch (e) {
      console.error(e)
      throw e;
    }
  }
  else{
    return null
  }
}

export const searchRecipesByKeyword = async (keyword: string) => {

    const endpoint = endpoints["seach-recipes-by-keyword"];

    if(endpoint){
        const url = endpoint.replace('{keyword}', keyword);
        let headers = new Headers();
        headers.append('Content-Type', 'application/json')
        headers.append('Accept', 'application/json')

        try{
        const response = await fetch(url, {
            headers: headers,
            mode: 'cors',
            method: 'GET'
        });

        return response// await response.json();

        }catch (e) {
        console.error(e)
        throw e;
        }
    }
    else{
        return null;
    }
}

export const getPopularRecipes = async () => {
  const endpoint = endpoints["get-popular-recipes"];

  if (endpoint) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    try {
      return await fetch(endpoint, {
        headers: headers,
        mode: "cors",
        method: "GET",
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  return null;
};
