import { useState } from 'react'
import { Button } from '../../components/Button/Button'
import './BrowseFilter.css'
import {MdOutlineExpandMore} from 'react-icons/md'
import {MdOutlineExpandLess} from 'react-icons/md'

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

export const BrowseFilter = () => {

    const [expandedFilter, setExpandedFilter] = useState(false)

    const [filter, setFilter] = useState("All")
    const [rating, setRating] = useState<number[]>([1, 5]);
    const [priceRange, setPriceRange] = useState<number[]>([1, 5]);

    const handleRatingChange = (event: Event, newValue: number | number[]) => {
        event.preventDefault()
        setRating(newValue as number[]);
    };

    const handlePriceRangeChange = (event: Event, newValue: number | number[]) => {
        event.preventDefault()
        setPriceRange(newValue as number[]);
    };

    function valuetext(value: number) {
        return `${value}°C`;
    }

    const handleFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter(event.target.value)
    }

    //console.log(filter)

    return(
        <div className="filter-container">
            <label htmlFor="browseFilter">Filters</label>
            <select className="drop-down-menu" value={filter} onChange={handleFilter}>
                <option value="Select a filter" disabled>Select a filter</option>
                <option value="All">All</option>
                <option value="Recipes">Recipes</option>
                <option value="Reviews">Reviews</option>
            </select>
            <div className='expander-container'>
                {expandedFilter
                    ?
                    <div className='expander-container'>
                        <MdOutlineExpandMore className='expander-symbol' onClick={() => {setExpandedFilter(false)}}/>
                        <p className='expander-text'>See less filters</p>
                    </div>
                    :
                    <div className='expander-container'>
                        <MdOutlineExpandLess className='expander-symbol' onClick={() => {setExpandedFilter(true)}}/>
                        <p className='expander-text'>See more filters</p>
                    </div>
                }
            </div>
            {expandedFilter &&
                    <div className='price-range-slider-container'>
                        <label className='rating-label' htmlFor="rating-label">Rating</label>
                        <Box sx={{ width: 300 }}>
                            <Slider
                                getAriaLabel={() => 'Temperature range'}
                                value={rating}
                                onChange={handleRatingChange}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                                step={1}
                                marks
                                min={1}
                                max={5}
                            />
                        </Box>
                        <label className='rating-label' htmlFor="pricerange-label">Price range</label>
                        <Box sx={{ width: 300 }}>
                            <Slider
                                getAriaLabel={() => 'Temperature range'}
                                value={priceRange}
                                onChange={handlePriceRangeChange}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                                step={1}
                                marks
                                min={1}
                                max={5}
                            />
                        </Box>
                    </div>
                }
            <Button 
                color={'#9ec1fa'} 
                fontColor={'black'} 
                width={'90%'} 
                margin={'12px 0'} 
                text={'Apply Filter'} 
                callback={() => {console.log("Implement me")}}
            />
        </div>
    )
}