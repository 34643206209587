import IngredientsChecker from "../../components/post-recipe/IngredientsChecker/IngredientsChecker";
import StepManager from "../../components/StepManager/StepManager";

export const IngredientAndStepManager = () => {
    return(
        <div>
            <IngredientsChecker/>
            <StepManager/>
        </div>
    )
}