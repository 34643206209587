import './Divider.css'

type Props = {
    color: string,
    margin: string,
    height?: string
}

export const Divider = ({color, margin, height}: Props) => {
    const styles = {
        "background": "linear-gradient(to right, transparent, " + color + ", transparent)",
        "margin": margin,
        "height": height ? height : "1px"
    }
    return(
        <div role="separator" style={styles} className="divider"></div>
    )
}