import './MyFeed.css'
import {IconButton} from "../../components/icon-button/IconButton";
import {useNavigate} from "react-router-dom";
import {myStore} from "../../stores/MyStore";
import {getMyPosts} from "../../service/UserService";
import {observer} from "mobx-react-lite";
import {PostCard} from "../../components/PostCard/PostCard";
import {MdOutlineRateReview, MdFormatListBulletedAdd, MdDinnerDining} from 'react-icons/md'
import {nanoid} from "nanoid";
import { useEffect } from 'react';
import { runInAction } from 'mobx';
import { Review } from '../../global.types';
import { recipeStore } from '../../stores/RecipeStore';

const MyFeed = observer( () => {

    useEffect(() => {
        recipeStore.clearStore()
    },[])

    useEffect(() => {
        if(myStore.posts.length === 0){
            const fetchData = async () => {
                try {
                    const response = await getMyPosts();
                    if (response && response.ok) {
                        const reviewData: Review[] = await response.json()
                        runInAction(() => {
                            reviewData.forEach(element => {
                                myStore.posts.push(element)
                            })
                        })
                    }
                } catch (error) {
                    // Handle any errors that occurred during the request
                    console.error(error);
                }
            };

            fetchData(); // Call the async function here
        }
    }, []);

    const navigator = useNavigate()
    const styles = {
        "fontSize":"4vh"
    }

    return (
        <div className="my-page-middle">
            <div className="icon-button-container">
                <IconButton text="Add Review" icon={<MdOutlineRateReview />} callback={() => {navigator('/addReview')}} styles={styles} id={''}/>
                <IconButton text="Add Recipe" icon={<MdFormatListBulletedAdd />} callback={() => {navigator('/addRecipe')}} styles={styles} id={''}/>
                <IconButton text="Post Expirience" icon={<MdDinnerDining />} callback={() => {navigator('/addReview')}} styles={styles} id={''}/>
            </div>
            {myStore.posts.length > 0
                ?
                <div>
                    {myStore.posts.map((element) => {
                        return(
                            <div key={nanoid()}>
                                <PostCard element={element}/>
                            </div>
                        )
                    })}
                </div>
                :
                <p className="no-post-yet-text" >No posts yet, create your first post by adding a restaurant review or one of your favorite recipe!</p>
            }
        </div>
    )
})
export default MyFeed // observer(UserFeed)