import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"
import { useState } from "react"
import './RecipeFilterModule.css'

type Props = {
    recipeType: string
}

export const RecipeFilterModule = (recipeType: Props) => {

    const [rating, setRating] = useState<number[]>([1, 5]);

    const handleRatingChange = (event: Event, newValue: number | number[]) => {
        event.preventDefault()
        setRating(newValue as number[]);
    };


    return(
        <div className="recipe-filter-module-container">
            <h1 className="" style={{color:"#838383"}}>Recipe - {recipeType.recipeType}</h1>
            <label className='rating-label' htmlFor="rating-label">Rating</label>
                <Box sx={{ width: 300 }}>
                    <Slider
                        getAriaLabel={() => 'Temperature range'}
                        value={rating}
                        onChange={handleRatingChange}
                        valueLabelDisplay="auto"                   
                        step={1}
                        marks
                        min={1}
                        max={5}
                    />
                </Box>
        </div>
    )
}