import {observer} from "mobx-react-lite";
import {recipeStore} from "../../stores/RecipeStore";
import './StepManager.css'

type Step = {
    header: string
    stepDescription: string
    stepImages: string[]
}

const StepManager = () => {
    return(
        <div className="step-manager">
            {recipeStore.steps.map((step: Step, index: number) => {
                return(
                    <div>
                        {index < recipeStore.steps.length ?
                            <p key={index}>{"Step " + ++index + " " + step.header}</p> : <div></div>}
                    </div>
                )
            })}
        </div>
    )
}
export default observer(StepManager)