import { observer } from "mobx-react-lite"
import LayoutThreeColumns from "../../layouts/LayoutThreeColumns/LayoutThreeColumns"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { getUserFromId } from "../../service/UserService"
import { userStore } from '../../stores/UserStore'
import UserInfo from "../../containers/UserInfo/UserInfo"
import UserFeed from "../../containers/UserFeed/UserFeed"
import UserRightSide from "../../containers/UserRightSide/UserRightSide"
import { isExpired } from "react-jwt"
import { myStore } from "../../stores/MyStore"
import { NavBar } from "../../components/NavBar/NavBar"

const UserView = () => {

    const {userId} = useParams()

    const isTokenExpired = isExpired(myStore.accessToken)

    useEffect( () => {
        const fetchData = async () => {
            if (userId) {
                try {
                    const response = await getUserFromId(userId);
                    if (response && response.ok) {
                        const data = await response.json(); // Assuming you're working with JSON data
                        userStore.name = data.name; // Update userStore with fetched data
                        userStore.id = userId;
                        userStore.title = data.title;
                        userStore.description = data.description;
                        userStore.profilePicture = data.profilePicture;
                        userStore.following = data.following;
                        userStore.posts = data.posts;
                    } 
                    else {
                        // TODO Handle the error case if the request was not successful
                    }
                } 
                catch (error) {
                    console.error(error);
                }
            }
          }
          fetchData()
    },[]) 

    return(
        <LayoutThreeColumns navBar={isTokenExpired ? <div></div> : <NavBar/>}>
            {{
                left: <UserInfo/>,
                middle: <UserFeed/>,
                right: <UserRightSide/>
            }}
        </LayoutThreeColumns>
    )
}
export default observer(UserView)