import { observer } from "mobx-react-lite";
//import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import './LandingpageNew.css';
//import landingpageHeaderImage from '../../resources/landingPageHeaderImg.jpg'; // adjust the path accordingly
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button/Button";
import { LandingPageTopContainer } from "../../containers/LandingPageTopContainer/LandingPageTopContainer";
import { RecipeCategoryModule } from "../../containers/recipeCategory/RecipeCategoryModule";
import { PopularRecipeModule } from "../../containers/ReviewBrowsingModule/PopularRecipeModule";
import { MdOutlineDinnerDining } from "react-icons/md";
import { MdOutlineFoodBank } from "react-icons/md";
import { TbChefHat } from "react-icons/tb";
import { useEffect, useState } from "react";


const LandingpageNew = () => {

    const navigate = useNavigate()


    const signUp = (event: React.MouseEvent) => {
        event.preventDefault()
        navigate('/signup')
    }


    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
    const handleResize = () => {
        setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
        });
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Detach the event listener on component unmount
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []); // Empty dependency array ensures that the effect runs only once on mount



    //Clear all cookies
    localStorage.clear()

    console.log(windowSize.width)

    return (
        <body className="landingpage-body-container" /* style={{ 
            background: `url(${landingpageHeaderImage}) top / cover no-repeat`,
            padding: 0,
            width: '100%',
            backgroundAttachment: 'fixed',
            margin: 0,
            
            overflow: 'hidden',
            position: 'relative', 
        }} */>
            <LandingPageTopContainer/>
            
            <section className="landingpage-signup-button-and-category-carusel-and-text-section">
                
                <div className="landingpage-signup-button-container">
                    <label className="landingpage-signup-button-container" htmlFor="">Start Creating and rating now!</label>
                    <Button color={"#749BFF"} fontColor={"White"} width={"15em"} margin={"2vw 0 0 0"} text={"Sign Up Now!"} callback={signUp}/>                        
                </div>
                <div className="landingpage-recipe-carusel-container">
                    <RecipeCategoryModule/>
                </div>

                <div className="landingpage-key-feature-text-container">
                    <div className="landingpage-key-feature-text-icon-and-text-container">
                        <MdOutlineDinnerDining style={{fontSize:"4em", filter: 'drop-shadow(3px 2px 2px rgba(0,0,0,0.28))'}}/>
                        <p className="landingpage-key-feature-text-column">Dive into the heart of Culinar's culinary creativity with our Recipe Crafting Studio! Whether you're a seasoned Food Ninja or an aspiring home cook, this virtual kitchen is your canvas for culinary expression. Explore innovative twists on classics or unleash your gastronomic experiments, sharing your culinary masterpieces with a community of like-minded enthusiasts. The Recipe Crafting Studio is where your culinary imagination takes center stage, allowing you to create and share extraordinary recipes with the world.</p>
                    </div>
                    <div className="landingpage-key-feature-text-icon-and-text-container">
                        <MdOutlineFoodBank style={{fontSize:"4em", filter: 'drop-shadow(3px 2px 2px rgba(0,0,0,0.28))',}}/>
                        <p className="landingpage-key-feature-text-column">Welcome to the bustling Culinar Community – a vibrant marketplace of flavors and cultures! Here, culinary adventurers from around the globe converge to share their passion for all things delicious. Browse through a treasure trove of recipes curated by fellow enthusiasts, discovering hidden gems and exploring diverse cuisines. More than just a collection of recipes, the Culinar Community is a dynamic hub where gastronomic tales unfold, connecting individuals through a shared love for the rich tapestry of global cuisine.</p>
                    </div>
                    <div className="landingpage-key-feature-text-icon-and-text-container">
                        <TbChefHat style={{fontSize:"4em", filter: 'drop-shadow(3px 2px 2px rgba(0,0,0,0.28))',}}/>
                        <p className="landingpage-key-feature-text-column">Connect, collaborate, and celebrate the joy of food in Culinar's Food Enthusiast Hub! Whether you're a seasoned chef, a home cook, or simply someone who appreciates a good meal, this is your digital dining table. Follow culinary maestros, exchange tips and tricks, and embark on a culinary journey with like-minded individuals. The Food Enthusiast Hub is where friendships are forged over shared recipes and culinary adventures, creating a lively space for the global community of food enthusiasts to unite and savor the flavors of togetherness.</p>
                    </div>                           
                </div>

            </section>

            <section className="landingpage-transperent-section"></section>
            
            <section className="landingpage-popular-recipes-section">
                <PopularRecipeModule/>   
            </section>

{/*             <footer className="landingpage-footer"/> */}
        </body>
    )
}
export default observer(LandingpageNew);