export const text = {
    "add-recipe-page-one":"Welcome to our delightful recipe section, where you can showcase your culinary creations and share them with the world!\n" +
        "\n" +
        "To ensure your recipe stands out, make sure the headers accurately reflect the name of the dish. This way, fellow food enthusiasts can easily " +
        "find and savor your delicious recipe. Additionally, don't forget to include a brief description of the dish, the number of people it serves, its " +
        "difficulty level, and the precise measurements of each ingredient, using units such as kilograms, liters, and more.\n" +
        "\n" +
        "Is your recipe calling for more ingredients? No problem at all! Just click the convenient plus button, and you can add as many additional " +
        "ingredients as you like. So go ahead and let your creativity flow in the kitchen, and share your culinary masterpieces with the world! Happy cooking!",

    "add-recipe-page-two":"Welcome to the exciting stage of perfecting your recipe and crafting a step-by-step guide to successfully prepare your culinary masterpiece!" +
        " Let's embark on this delightful journey together as we create a delectable dish that will leave everyone craving for more.\n" +
        "\n" +
        "To get started, let's structure your recipe with a touch of elegance and clarity. Begin each step with a captivating header of your choice, adding a dash of " +
        "creativity to entice your readers. Then, proceed to describe each step briefly yet precisely, ensuring that the process is easy to follow and understand.\n" +
        "\n" +
        "For your convenience, we have provided an ingredient checklist in the left section. This checklist will serve as a helpful companion, guiding you to remember " +
        "the ingredients that need to be included and those that have already found their way into the recipe.\n" +
        "\n" +
        "With passion, precision, and our comprehensive guide at your fingertips, you are all set to create a remarkable dish that will leave an indelible impression " +
        "on the taste buds of all who savor it. So, let the culinary adventure begin, and let your creativity and skills take center stage in the art of cooking!"
}