import LayoutThreeColumns from "../../layouts/LayoutThreeColumns/LayoutThreeColumns";
import {observer} from "mobx-react-lite";
import {InfoTextPageOne} from "../../components/post-recipe/pageOne/InfoTextPageOne";
import {recipeStore} from "../../stores/RecipeStore";
import PageOne from "../../components/post-recipe/pageOne/PageOne";
import PageTwo from "../../components/post-recipe/pageTwo/PageTwo";
import {IngredientAndStepManager} from "../../containers/ingredient-and-step-manager/IngredientAndStepManager";
import {InfoTextPageTwo} from "../../components/post-recipe/pageTwo/InfoTextPageTwo";
//import { useEffect } from "react";

const AddRecipe = () => {
    
/*     useEffect(() => {
        recipeStore.clearStore()
    },[]) */

    return(
        <LayoutThreeColumns>
            {{
                left: recipeStore.getPageOne() ? <div></div> : <IngredientAndStepManager/> ,
                middle: recipeStore.getPageOne() ? <PageOne/> : <PageTwo/>,
                right: recipeStore.getPageOne() ? <InfoTextPageOne/> : <InfoTextPageTwo/> 
            }}
        </LayoutThreeColumns>
    )
}
export default observer(AddRecipe)