import './EditTextButton.css'
import {AiOutlineEdit} from 'react-icons/ai'

export const EditTextButton = (props) => {

    const styles = {
        "fontSize":props.size,
        "color":props.color
    }

    return(
        <div className="edit-text-button-container" onClick={props.callback}>
            <AiOutlineEdit style={styles}/>
        </div>
    )
}