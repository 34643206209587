import { observer } from "mobx-react-lite"
import './SignIn.css'
import { Button } from '../Button/Button'
import React from "react"
import { myStore } from "../../stores/MyStore"

type Props = {
    callback: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const SignIn = ({callback}: Props) => {

    const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        myStore.email = event.target.value
    }
    const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        myStore.password = event.target.value
    }

/*     const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });

      useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
    
        // Attach the event listener
        window.addEventListener('resize', handleResize);
    
        // Detach the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); // Empty dependency array ensures that the effect runs only once on mount */
    

    return(
        <div className="sign-in-container">
            <form >
                <div className="sign-in-form-group">
                    <input className="sign-in-email-input" type="text" placeholder="Email" onChange={handleEmail} value={myStore.email}/>
                    <input className="sign-in-password-input"  type="password" name="" id="" placeholder="Password" onChange={handlePassword} value={myStore.password}/>
                    <Button color={"#749BFF"} fontColor={"White"} width={"10em"} margin={ "0 0 0 0" } text={"Sign In!"} callback={ callback }/>
                </div>
            </form>
        </div>
    )
}
export default observer(SignIn)