
import './recipeCard.css'
import {myStore} from "../../../stores/MyStore";
import {useLocation, useNavigate} from "react-router-dom";
import {Rate} from "../../Rate/Rate";
import {AiOutlineStar} from "react-icons/ai";
import {observer} from "mobx-react-lite";
import {nanoid} from "nanoid";
import {Recipe} from "../../../global.types";
import { recipeStore } from '../../../stores/RecipeStore';
import { userStore } from '../../../stores/UserStore';
import { toJS } from 'mobx';
import { getRecipeFromRecipeId } from '../../../service/RecipeService';

type Props = {
    recipe: Recipe
}
const RecipeCard = ({recipe}: Props) => {

    console.log(toJS( recipe))

    const {header, description,headerImages} = recipe.content
    const {authorDetailes, rating} = recipe


    const {createdAt} = recipe
    const date = new Date(createdAt)
    const location = useLocation()
    const currentView = location.pathname
    const options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'long',
        year: 'numeric' as 'numeric', // Explicitly set the type
    };
    const formattedDate = date.toLocaleDateString('en-GB', options).replace(/\//g, '');
    
    const navigator = useNavigate()
    const handleSeeRecipe = async () => {
        const expandedRecipe = await getRecipeFromRecipeId(recipe._id)
        if(expandedRecipe.ok){
            const data = await expandedRecipe.json();
            recipeStore.populateStore(data)
        }
        navigator('/recipe')
    }


    return(
        <div className="recipe-card-container">
            <div className='recipe-card-img-name-date-rate-container'>
                <img className="recipe-card-profile-picture" src={currentView === '/mypage' || currentView === '/myPage' ? myStore.profilePicture : authorDetailes?.profilePicture ? authorDetailes.profilePicture : userStore.profilePicture} alt=""/>
                <div className='recipe-card-date-name-container'>
                    <h3 className="recipe-card-name-element">{currentView === '/mypage' || currentView === '/myPage' ? myStore.fullName : authorDetailes?.name}</h3>
                    <p className="recipe-card-review-date">{formattedDate}</p>
                </div>

                <div className="recipe-card-rate-container">
                    <Rate elements={5}
                            symbol={<AiOutlineStar/>}
                            size="1.5vw"
                            rate={rating}
                            activeColor="#ffcc00"
                            notActiveColor="#4d4d4d"/>
                    {/* <p className="recipe-card-votes">({votes}) {votes  === 1 ? "vote" : "votes"}</p> */}
                </div>

            </div>

            <div className='recipe-card-header-description-container'>
                <h1 className="review-card-header">{header}</h1>
                <p className="recipe-description">{description}</p>
            </div>


            {headerImages && headerImages.map(image => {
                return(
                    <img key={nanoid()} className="header-image-recipe-card" src={image} alt=""/>
                )
            })}

            <div className='recipe-card-see-recipe-and-see-comments-container'>
                <p className='recipe-card-see-recipe' onClick={handleSeeRecipe}>See Recipe</p>
                <p className='recipe-card-see-comments'>8 Comments</p>
            </div>
        </div>
    )
}
export default observer(RecipeCard)