import React from 'react';
import {MdArrowBack, MdArrowForward} from 'react-icons/md'
import {useNavigate} from "react-router-dom";
import './NavigationButtons.css'

type Props = {
    type: string,
    size: string
    callbackBack?: (() => void)
}

export const NavigationButtons: React.FC<Props> = ({type, size, callbackBack}: Props) => {

    const navigate = useNavigate()

    const styles = {
        "fontSize":size,
    }

    switch (type){
        case "back":{
            return(
                <div className="navigation-buttons" style={styles} onClick={callbackBack ? callbackBack : () => {navigate(-1)}}>
                    <MdArrowBack/>
                </div>
            )
        }
        case "next":{
            return(
                <div className="navigation-buttons" style={styles} onClick={() => navigate(+1)}>
                    <MdArrowForward/>
                </div>
            )
        }
        case "both":{
            return(
                <div className="navigation-buttons" style={styles}>
                    <MdArrowBack onClick={callbackBack ? callbackBack : () => {navigate(-1)}} />
                    <MdArrowForward onClick={() => navigate(+1)}/>
                </div>
            )
        }
        default:{
            console.error("When using NavigationButtons, parse props 'type' = 'back', 'next' or 'both' ")
            return null
        }
    }

/*    return(
        <div style={styles}>

            <MdArrowBack/>
            <MdArrowForward/>
        </div>
    )*/
}