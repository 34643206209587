import {  useState } from "react"
import { decodeToken } from "react-jwt"
import { useNavigate } from "react-router-dom"
import SignIn from "../../components/SignIn/SignIn"
import { signIn } from "../../service/AuthenticationService"
import { myStore } from "../../stores/MyStore"
import './LandingPageTopContainer.css'
/* import { PiSignIn } from "react-icons/pi";
import { IconButton } from "../../components/icon-button/IconButton"
import { Modal } from "../../components/modal/Modal" */


export const LandingPageTopContainer = () => {

    const navigate = useNavigate()
    const [error, setError] = useState(false)
    const [errorMessage, setErrormessage] = useState("")
    /* const [signInModalOpen, setSignInModalOpen] = useState(false)
 */

/*     const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });

      useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
    
        // Attach the event listener
        window.addEventListener('resize', handleResize);
    
        // Detach the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); // Empty dependency array ensures that the effect runs only once on mount
    
 */

    const handleSignIn = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        const response = await signIn()
        
        if (response.ok) {
/*             setError(false)
            if(emailInput && emailInput.current){
                emailInput.current.value = ""
            }
            if(passwordInput && passwordInput.current) {
                passwordInput.current.value = ""
            } */

            const responseData = await response.json()
            const {email, name, profilePicture, token, title, description} = await responseData

            const userToken = decodeToken(responseData.token)

            myStore.id = (userToken as { user_id: string }).user_id;
            myStore.email = email
            myStore.fullName = name
            myStore.profilePicture = profilePicture
            myStore.title = title
            myStore.description = description
            myStore.accessToken = token

            navigate('/mypage')
            myStore.storeUserStoreInLocalStorage();
        }
        else if(response.status === 401){
            setErrormessage("Invalid username or password!")
            setError(true)
        }
        else{
            myStore.email = ""
            myStore.password = ""
/*             if(emailInput && emailInput.current) {
                emailInput.current.value = ""
            }
            if(passwordInput && passwordInput.current) {
                passwordInput.current.value = ""
            } */
        }
    }

/*     const signInModal = () => {
        return (
            <div>
                Modal ipen
            </div>
        )
    } */


    return(
        <div className="landingpage-top-container-wrapper">
            <div className="landingpage-header-container">
                <h1 className="landingpage-header-h1">Welcome to Culinar</h1>
                <h2 className="landingpage-header-h2">Explore, Create, and Savor Delightful Recipes</h2>
            </div>
            {
            <div className="landingpage-signin-and-error-container">
                <SignIn callback={handleSignIn}/>
                <p className="landing-page-signin-error-message">{error && errorMessage}</p>
            </div>
            }
        </div>
    )
}