import './FollowAndSearchMyPage.css'
import {observer} from "mobx-react-lite";
//import FollowingWidget from "../../components/FollowingWidget/FollowingWidget";
import SearchUser from "../../components/SearchUser/SearchUser";
import { useEffect, useState } from 'react';
import { fetchFollowingWidget } from '../../service/UserService';
import { myStore } from '../../stores/MyStore';
import { toJS } from 'mobx';
import FollowingWidget from '../../components/FollowingWidget/FollowingWidget';

interface WidgetObject {
    _id: string;
    name: string;
    profilePicture: string;
}


const FollowingAndSearchMyPage = () => {

    const [followingWidget, setFollowingWidget] = useState<WidgetObject[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const followingObject = await fetchFollowingWidget(10, myStore.id);
                const data = await followingObject.json();
                setFollowingWidget(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []); // Empty dependency array means this effect runs only once after the component mounts
 
    console.log(toJS(myStore.followingWidgetObjects))
    return(
        <div className="my-page-right">
            <FollowingWidget listOfUsers={followingWidget}/>
            <SearchUser/>
        </div>
    )
}
export default observer(FollowingAndSearchMyPage)