import {endpoints} from "../resources/endpoints";
import {myStore} from "../stores/MyStore";
//import {decodeToken} from "react-jwt";
//import defaultProfilePicture from "../resources/default_profilepicture.png";
//import {dummyData} from "../resources/dummyData";

export const signUp = async () => {

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    const url = endpoints["sign-up"] //"http://localhost:8080/sign-up"
    const body = {
        email: myStore.email,
        firstName: myStore.firstName,
        lastName: myStore.lastName,
        password: myStore.password,
    }
    return await fetch(url,{
        headers: headers,
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(body)
    })
}

export const signIn = async () => {

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    const url = endpoints["sign-in"]//"http://localhost:8080/sign-in"
    const body = {
        email: myStore.email,
        password: myStore.password,
    }
    return await fetch(url, {
        headers: headers,
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify( body )
    })
}
