import {myStore} from "../../../stores/MyStore";
import {Rate} from "../../Rate/Rate";
import "./reviewCard.css"
import {observer} from "mobx-react-lite";
import {useLocation, useNavigate} from "react-router-dom";
import {AiOutlineStar, AiOutlineEdit} from 'react-icons/ai'
import {MdOutlinePaid} from 'react-icons/md'
import {IconButton} from "../../icon-button/IconButton";
import {reviewStore} from "../../../stores/ReviewStore";
import React, {useEffect, useState} from "react";
import {nanoid} from "nanoid";
import {Divider} from "../../Divider/Divider";
import {Recipe, Review} from "../../../global.types";
//import defaultProfilePicture from '../../../resources/default_profilepicture.png'


type Props = {
    reviewElement: Review
}

const ReviewCard = ({reviewElement}: Props) => {

    function isReview(post: Review | Recipe): post is Review {
        return (post as Review).author !== undefined && (post as Review).createdAt !== undefined;
    }

    const navigator = useNavigate()
    const location = useLocation()
    //let currentView = location.pathname

    const [currentLocation, setCurrentLocation] = useState("")

    const [showFullText, setShowFullText] = useState(false);

    useEffect(() => {
        setCurrentLocation(location.pathname)
    }, [location.pathname]);

    const {rating, priceRange, restaurantName, type, address, city, country, title: header, review, images} = reviewElement.content
    const {postType} = reviewElement;
    const {authorDetailes} = reviewElement
    const lineCount = 1; // Desired number of visible lines

    // Function to truncate the text to a certain number of visible lines
    const truncateTextToLines = (text: string, lines: number) => {
        const linesArray = text.split('\n');
        return linesArray.slice(0, lines).join('\n');
    };

    const truncatedReview = truncateTextToLines(review, lineCount);
    const shouldShowSeeMore = review !== truncatedReview; // Check if the review is longer than the truncated version


/*    const truncatedReview = review.slice(0, 150);
    const shouldShowSeeMore = review.length > 150;*/

    const date = new Date(reviewElement.createdAt)
    const options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'long',
        year: 'numeric' as 'numeric', // Explicitly set the type
    };
    const formattedDate = date.toLocaleDateString('en-GB', options).replace(/\//g, '');

    const editCallback = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        const id = (event.target as HTMLDivElement).id; // Get the id from the clicked element
        const post = myStore.posts.find(post => post._id === id)
        const trimmedPostType = postType.toString().trim()

        if(trimmedPostType === 'Review') {
            //const jsPost = toJS(post)

            if (post !== undefined) {
                if (isReview(post)) {
                    reviewStore.populateStore(post)
                    reviewStore.editingReview = true
                    navigator("/addReview")
                }
            }
        }
    };

    return (
        <div className="review-container">
            <img className="review-card-profile-picture" src={currentLocation === '/myPage' || currentLocation === '/mypage' ? myStore.profilePicture : authorDetailes?.profilePicture ? authorDetailes.profilePicture : "none"} alt=""/>
            <h3 className="name-element">{currentLocation === '/myPage' || currentLocation === '/mypage' ?  myStore.fullName : authorDetailes?.name}</h3>
            <p className="review-date">{formattedDate}</p>
            <div className="review-images-container">
                {images.map((image) => {
                    return(
                        <div className="review-image-container" key={nanoid()}>
                            <img className="review-image-fit" src={image} alt=""/>
                        </div>
                    )
                })}
            </div>
            <div className="content-container">
            <div className="restaurant-and-type-container">
                <h1 className="restaurant-name">{restaurantName}</h1>
                <p className="type">{type}</p>
            </div>

            <h2 className="header">{header}</h2>

            <Divider color="#ccc" margin="28px 0 0 0"/>

            <div className="rater-container-review">
                <Rate elements={5} symbol={<AiOutlineStar/>} size="2vw" rate={rating} activeColor="#ffcc00"
                      notActiveColor="#4d4d4d"/>
                <Rate elements={5} symbol={<MdOutlinePaid/>} size="2vw" rate={priceRange}
                      activeColor="#cc9d63" notActiveColor="#4d4d4d"/>
            </div>

            <Divider color="#ccc" margin="12px 0 0 0"/>

            <div >
                {/* Render the truncated or full review */}
                {showFullText
                    ?
                        <div>
                            <p className="review">{review}</p>
                            <div className="location-section">
                                <p className="address-elements">{address}, </p>
                                <p className="address-elements">{city}, </p>
                                <p className="address-elements">{country}</p>
                            </div>
                        </div>
                    :
                        <p className="review">{truncatedReview}</p>
                }
                {/* Render "See More" link if the review is too long */}
                {shouldShowSeeMore && (
                    <span onClick={() => setShowFullText(!showFullText)} style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline', fontSize: '14px'}}>
                    {showFullText ? 'See Less' : 'See More'}
                    </span>
                )}
            </div>
            </div>

            {currentLocation === '/myPage' || currentLocation === '/mypage'
                ?
                <div className="edit-button-review-container">
                    <IconButton id={reviewElement._id} icon={<AiOutlineEdit/>} text="" styles={{"marginLeft":"90%", "fontSize":"1.3vw"}} callback={editCallback}/>
                </div>
                :
                <div></div>}
        </div>
    )
}
export default observer(ReviewCard)
