import {makeAutoObservable, runInAction} from "mobx"
import {Recipe, Review, WidgetObject} from "../global.types";


/*** This store is for the users own variables ***/

class MyStore {
    _id: string = ""
    _email: string = "";
    _firstName: string = "";
    _lastName: string = "";
    _fullName: string = "";
    _password: string = "";
    _repeatedPassword: string = "";
    _profilePicture: string = "";
    _roles: string[] = [];
    _followingLength: number = 0;
    _followingWidgetObjects: Array<WidgetObject> = [];
    _posts: Array<Recipe | Review> = [];
    _restaurantReviews: Array<Review> = [];
    _recipes: Array<Recipe> = [];
    _recipesLength: number = 0;
    _accessToken: string = "";
    _title: string = "";
    _description: string = "";

    constructor() {
        const storedData = localStorage.getItem('user')
        if (storedData !== null && storedData) {
            const parsedData = JSON.parse(storedData);
            this.followingWidgetObjects = [];
            Object.assign(this, parsedData);
        }
        makeAutoObservable(this,
            {},
            {autoBind:true}//For non-arrow-functions bind
        )
    }
    setProfilePicture(imageURL: string){
        this.profilePicture = imageURL
    }
    setUserTitle(title: string){
        this.title = title
    }
    setUserDescription(description: string){
        this.description = description
    }
    storeUserStoreInLocalStorage() {
        const userToStore = {
            id: this.id,
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            fullName: this.fullName,
            profilePicture: this.profilePicture,// this.profilePicture ? this.profilePicture : defaultProfilePicture,
            roles: this.roles,
            followingLength: this.followingLength,
            followingWidgetObjects: this.followingWidgetObjects,
            recipesLength: this.recipesLength,
            accessToken: this.accessToken,
            title: this.title,
            description: this.description
        }
        localStorage.clear()
/*         const userTest =  localStorage.getItem('user');
        console.log(userTest) */
        localStorage.setItem('user',JSON.stringify(userToStore))
    }
    addFollower(id: string, name: string, profilePicture: string){
        const widgetObject = {
            _id: id,
            name: name,
            profilePicture: profilePicture
        }
        this.followingWidgetObjects.push(widgetObject)
    }
    incrementFollowing(){
        this.followingLength ++
    }

    deleteUserPost = async (reviewId: string) => {
        runInAction(() => {
            try{
                // Find the index of the post with the specified reviewId
                const index = this._posts.findIndex((post) => post._id === reviewId);
                // If the post is found (index is not -1), remove it from the array
                if (index !== -1) {
                    this._posts.splice(index, 1);
                } else {
                    console.error("Post not found");
                }
            }catch (e: any) {
                console.error(e.message)
            }
        })
    }

    clear() {
        runInAction(() => {
            this._id = "";
            this._email = "";
            this._firstName = "";
            this._lastName = "";
            this._fullName = "";
            this._password = "";
            this._repeatedPassword = "";
            this._profilePicture = "";
            this._roles = [];
            this._followingLength = 0;
            this._followingWidgetObjects = [];
            this._posts = [];
            this._restaurantReviews = [];
            this._recipes = [];
            this._recipesLength = 0;
            this._accessToken = "";
            this._title = "";
            this._description = "";
        })
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get email() {
        return this._email;
    }

    set email(value) {
        this._email = value;
    }

    get firstName() {
        return this._firstName;
    }

    set firstName(value) {
        this._firstName = value;
    }

    get lastName() {
        return this._lastName;
    }

    set lastName(value) {
        this._lastName = value;
    }

    get fullName() {
        return this._fullName;
    }

    set fullName(value) {
        this._fullName = value;
    }

    get password() {
        return this._password;
    }

    set password(value) {
        this._password = value;
    }

    get repeatedPassword() {
        return this._repeatedPassword;
    }

    set repeatedPassword(value) {
        this._repeatedPassword = value;
    }

    get profilePicture() {
        return this._profilePicture;
    }

    set profilePicture(value) {
        this._profilePicture = value;
    }

    get roles() {
        return this._roles;
    }

    set roles(value) {
        this._roles = value;
    }

    get followingLength() {
        return this._followingLength;
    }

    set followingLength(value) {
        this._followingLength = value;
    }

    get followingWidgetObjects() {
        return this._followingWidgetObjects;
    }

    set followingWidgetObjects(value) {
        this._followingWidgetObjects = value;
    }

    get posts() {
        return this._posts;
    }

    set posts(value) {
        this._posts = value;
    }

    get restaurantReviews() {
        return this._restaurantReviews;
    }

    set restaurantReviews(value) {
        this._restaurantReviews = value;
    }

    get recipes() {
        return this._recipes;
    }

    set recipes(value) {
        this._recipes = value;
    }

    get recipesLength() {
        return this._recipesLength;
    }

    set recipesLength(value) {
        this._recipesLength = value;
    }

    get accessToken() {
        return this._accessToken;
    }

    set accessToken(value) {
        this._accessToken = value;
    }

    get title() {
        return this._title;
    }

    set title(value) {
        this._title = value;
    }

    get description() {
        return this._description;
    }

    set description(value) {
        this._description = value;
    }
}
export const myStore = new MyStore()
