import { ReactNode } from "react"
import './LayoutHorizontal.css'
import { Divider } from "../../components/Divider/Divider"
import { NavigationButtons } from "../../components/NavigationButtons/NavigationButtons";

interface LayoutProps {
    navBar: ReactNode,
    children: {
      top?: ReactNode;
      middle?: ReactNode;
      bottom?: ReactNode;
    };
  }


export const LayoutHorizontal: React.FC<LayoutProps> = ({navBar, children }) => {

    return(
        <div>
            <header>
                {navBar && navBar}
                <div className="navigation-buttons-container">
                    <NavigationButtons type={"back"} size={"2vw"}/>
                </div>
            </header>
            <main className="layout-horizontal-main-container">
                <div className="horizontal-top-container">{children.top}</div>
                <Divider color={"#D9D9D9"} margin={"10vh 0"} height="3px"/>
                <div className="horizontal-bottom-container">{children.bottom}</div>
            </main>
        </div>
    )
}