import './PublicFeed.css'
import {useEffect} from "react";
import {getFeed} from "../../service/UserService";
import {observer} from "mobx-react-lite";
import {feed} from '../../stores/FeedStore'
import {PostCard} from "../../components/PostCard/PostCard";
import { Recipe, Review } from '../../global.types';


const PublicFeed = () => {

    useEffect(() => {
        async function fetchData() {
            try {
                if(!feed.feed.length) {
                    const response = await getFeed();
                    const data = await response.json()
                    data.forEach((element: Review | Recipe) => {
                        feed.addFeed(element)
                    })
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData().then(() => {});
    }, []);

    return(
        <div>
            {feed.feed.map((post, index) => {
                return(
                    <div key={index}>
                        <PostCard element={post}/>
                    </div>
                )
            })}
        </div>
    )
}
export default observer(PublicFeed)