import {makeAutoObservable, runInAction} from "mobx"

interface ReviewStoreInterface {
    _id: string,
    author: string,
    createdAt: string,
    postType: string,
    content:{
        userId: string;
        id: string;
        restaurantName: string;
        address: string;
        country: string;
        city: string;
        priceRange: number;
        review: string;
        rating: number;
        title: string;
        type: string;
        images: string[]; // Replace 'any[]' with the appropriate type for images
        date: string;
        editingReview: boolean;
    }
}
export class ReviewStore {

        _userId: string = ""
        _id: string = ""
        _restaurantName: string = "";
        _address: string = ""
        _country: string = ""
        _city: string = "";
        _priceRange: number = 0;
        _review:  string = "";
        _rating: number = 0;
        _title: string = "";
        _type: string = "";
        _images: string[] = [];
        _date: string = "";
        _editingReview: boolean = false

    constructor() {
        makeAutoObservable(this,
            {},
            {autoBind:true}//For non-arrow-functions bind
        )
    }
    addImages(images: string[]){
        images.forEach(image => this._images.push(image))
        //this.images.push(images)
    }
    clearImages(){
        this._images = []
    }
    clearStore() {
        runInAction(() => {
            this._restaurantName = '';
            this._address = '';
            this._country = '';
            this._city = '';
            this._priceRange = 0;
            this._review = '';
            this._rating = 0;
            this._title = '';
            this._type = '';
            this._images = [];
            this._date = '';
        })
    }

    populateStore(review: ReviewStoreInterface){
        Object.assign(this, review.content)
        this._id = review._id
    }

    toJSON() {
        return {
            restaurantName: this._restaurantName,
            address: this._address,
            country: this._country,
            city: this._city,
            priceRange: this._priceRange,
            review: this._review,
            rating: this._rating,
            header: this._title,
            type: this._type,
            images: this._images,
            date: this._date,
            //userId: this._userId,
            //reviewId: this._reviewId,
        };
    }

    setRestaurantName(name: string){
        this.restaurantName = name
    }

    get restaurantName() {
        return this._restaurantName;
    }

    set restaurantName(value) {
        runInAction(() => {
            this._restaurantName = value;
        })
    }

    get address() {
        return this._address;
    }

    set address(value) {
        this._address = value;
    }

    get country() {
        return this._country;
    }

    set country(value) {
        this._country = value;
    }

    get priceRange() {
        return this._priceRange;
    }

    set priceRange(value) {
        this._priceRange = value;
    }

    get review() {
        return this._review;
    }

    set review(value) {
        this._review = value;
    }

    get rating() {
        return this._rating;
    }

    set rating(value) {
        this._rating = value;
    }

    get header() {
        return this._title;
    }

    set header(value) {
        this._title = value;
    }

    get images() {
        return this._images;
    }

    set images(value) {
        this._images = value;
    }

    get type() {
        return this._type;
    }

    set type(value) {
        this._type = value;
    }

    get city() {
        return this._city;
    }

    set city(value) {
        this._city = value;
    }

    get date() {
        return this._date;
    }

    set date(value) {
        this._date = value;
    }

    get userId() {
        return this._userId;
    }

    set userId(value) {
        this._userId = value;
    }

    get editingReview() {
        return this._editingReview;
    }
    set editingReview(value) {
        runInAction(() => {
            this._editingReview = value;
        })
    }
    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }
}
export const reviewStore = new ReviewStore()
