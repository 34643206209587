import './Modal.css'
import {ReactNode} from "react";

type Props = {
    isOpen:  boolean,
    onClose: () => void
    children: ReactNode
}

export const Modal = ({ isOpen, onClose, children }: Props) => {

    if (!isOpen) return null;
    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close-button" onClick={onClose} data-testid="modal-close-test">
                  &times;
                </span>
                {children}
            </div>
        </div>
    );
};