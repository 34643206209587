import './SmallFriendProfilePicture.css'
//import defaultProfilePicture from '../../resources/default_profilepicture.png'
import {Tooltip} from "react-tooltip";

type Props = {
    id: string,
    profilePicture?: string,
    name: string
}

export const SmallFriendProfilePicture = ({profilePicture, name}: Props) => {

    return(
        <div className="small-friend-card-profile-container">
            <span data-tooltip-id="user-name-tooltip" data-tooltip-content={name}>
                <div>
                    <img src={profilePicture} alt="User" className="small-friend-card-profile-picture"/>
                </div>
            </span>
            <Tooltip id="user-name-tooltip"/>
        </div>
    )
}