//import ProfilePicture from "../../components/profilePicture/profilePicture"
import { useEffect, useState } from "react"
import LayoutThreeColumns from "../../layouts/LayoutThreeColumns/LayoutThreeColumns"
import { Ingredients } from "../../containers/ingedients/Ingredients"
import RecipeModule from "../../containers/recipeModule/RecipeModule"
//import { recipeStore } from "../../stores/RecipeStore"
import './RecipeView.css'
import {getProfilePictureFromUserId} from '../../service/UserService'
import { recipeStore } from "../../stores/RecipeStore"
import { ProfilePictureAndName } from "../../containers/ProfilePictureAndName/ProfilePictureAndName"
import { isExpired } from "react-jwt"
import { myStore } from "../../stores/MyStore"
import { NavBar } from "../../components/NavBar/NavBar"

export const RecipeView = () => {

    const [profilePicture, setProfilePicture] = useState('');
    const [name, setName] = useState('');

    const isTokenExpired = isExpired(myStore.accessToken)


    useEffect(() => {
 
        const fetchData = async () => {
            try {
              const response = await getProfilePictureFromUserId(recipeStore.author);
              if (response && response.ok) {
                const data = await response.json(); // Assuming you're working with JSON data
                setProfilePicture(data.profilePicture); // Assuming data is an array of ReactElements
                setName(data.name);
              } else {
                // Handle the error case if the request was not successful
              }
            } catch (error) {
              console.error(error);
            }
        }        
        fetchData();
      }, []);

    return(
        <LayoutThreeColumns navBar={isTokenExpired && <div></div> || <NavBar/>}>
            {{
                left: <Ingredients/>,
                middle: <RecipeModule/>,
                right: <ProfilePictureAndName profilePicture={profilePicture} name={name}/> 
            }}
        </LayoutThreeColumns>
    )
}
