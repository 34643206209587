import {useRef, useState} from "react";
import './UploadImageButton.css'
import {myStore} from "../../stores/MyStore";
import {observer} from "mobx-react-lite";
import {AiOutlineCamera} from 'react-icons/ai'
import {AiOutlineUpload} from 'react-icons/ai'
import {MdOutlineCancel} from 'react-icons/md'
//import { toJS } from "mobx";


const UploadImageButton = (props) => {

    const inputFile = useRef(null)
    const [imageSelected, setImageSelected] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState("")

    const styles = {
        "fontSize":"2vw",
    }

    const acceptedFileTypes = ".jpeg, .jpg, .png"
    const handleUpload = async (event) => {

        event.preventDefault()

        const headers = new Headers()
        headers.append("token",myStore.accessToken)

        const formData = new FormData()
        const image = await fetch(myStore.profilePicture)
        const blob = await image.blob()
        const file = new File([blob], selectedFileName, {type: 'image/jpg'})
        formData.append('profilePicture', file)

        const response = await fetch(props.url, {
            headers: headers,
            method: 'POST',
            mode: 'cors',
            body: formData
        })

        if(response.ok){
            //localStorage.clear()
            const data = await response.json()
            console.log(data)
            myStore.profilePicture = data.profilePictureURL
            myStore.storeUserStoreInLocalStorage()
            //localStorage.setItem("user", JSON.stringify( toJS(user)))
        }

        setImageSelected(false)
        myStore.posts.forEach(post => {
            post.profilePicture = file
        })

    }
    const handleFileChange = (event) => {

        const file = event.target.files[0];
        console.log(file)

        if (file) {
            // Image selected
            setImageSelected(true);
            setSelectedFileName(file.name); // Update the selected file name
            const sel = URL.createObjectURL(file)
            console.log(sel)
            myStore.profilePicture = sel
            props.previewCallback(sel)
        } else {
            // User canceled the selection
            setImageSelected(false);
            setSelectedFileName(""); // Clear the selected file name
        }
    };

    const handleImageSelect = () => {
        inputFile.current.click()
        console.log("File: " + inputFile.current.value)
    }




    return(
        <div>
            {!imageSelected
                ?
            <div className="edit-profile-picture-button" onClick={handleImageSelect}>
                <input className="file-upload" onChange={handleFileChange} type='file' id='file' name="file" ref={inputFile} style={{display: 'none'}} accept={acceptedFileTypes}/>
                <AiOutlineCamera style={styles}/>
            </div>
                :
            <div >
                <input className="file-upload" onChange={handleFileChange} type='file' id='file' name="file" ref={inputFile} style={{display: 'none'}} accept={acceptedFileTypes}/>
                <div className="upload-button-container">
                    <AiOutlineUpload className="edit-profile-picture-button" style={styles} onClick={handleUpload}/>
                    <MdOutlineCancel className="cancel-upload-profile-picture" style={styles} onClick={props.cancelCallback}/>
                    <p className="upload-button-text">Press the upload button to upload {selectedFileName}</p>
                </div>
            </div>
            }
        </div>
    )
}
export default observer(UploadImageButton)

/*
<div className="edit-hover-effect">
    <span onClick={cancelEdit} className="material-symbols-outlined">cancel</span>
</div>
<div className="edit-hover-effect">
    <span onClick={confirmChanges} className="material-symbols-outlined">check_small</span>
</div>*/
