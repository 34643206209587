import { nanoid } from 'nanoid'
import { Divider } from '../../components/Divider/Divider'
import { recipeStore } from '../../stores/RecipeStore'
import './Ingredients.css'
import { NavigationButtons } from '../../components/NavigationButtons/NavigationButtons'
import { Rate } from '../../components/Rate/Rate'
import { IoStar } from 'react-icons/io5'


export const Ingredients = () => {

    //const isTokenExpired = isExpired(myStore.accessToken)

    console.log("Rating: ", recipeStore.rating)

    return(
        <div>
            <div className='ingredient-container-navigation-button-container'><NavigationButtons type={'back'} size={'5vh'}/></div>
            <div className='ingredient-module-image-and-ingredient-container'>
                <img className='ingredient-module-image' src={recipeStore.headerImage[0]} alt="" />
                <div className="ingredients-container">
                    <Rate 
                        symbol={<IoStar />} 
                        elements={5} 
                        size="2vw" 
                        notActiveColor={"grey"} 
                        activeColor={"#F1C644"} 
                        rate={recipeStore.rating}
                    />   
                    <h1 className='ingredients-header'>Ingredients</h1>
                    {recipeStore.ingredients.map(ingredient => {
                        return(
                            <div key={nanoid()}>
                                <div className='ingredient-and-unit-container'>                                   
                                    <div className='ingredients-and-seperator'>
                                        <h3>{ingredient.ingredient} </h3>
                                    </div>
                                    <p className='ingredient-unit'>{ingredient.unit}</p>
                                </div>
                                <Divider color={'lightblue'} margin={'12px'}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>

    )
}