import ProfilePicture from "../../components/profilePicture/profilePicture"
import './ProfilePictureAndName.css'
//import defaultProfilePicture from '../../resources/default_profilepicture.png'
 
type Props = {
    profilePicture: string,
    name: string
}

export const ProfilePictureAndName = ({profilePicture, name}: Props) => {

    //const picture = profilePicture ? profilePicture : defaultProfilePicture
 
    return(
        <div className="profile-picture-and-name-container">
            <div className="profile-picture-and-name-image"><ProfilePicture src={profilePicture} size={"10vw"}/></div>
            <p className="profile-picture-and-name-text">{name}</p>
        </div>
    )
}