import './signUp.css'

import LayoutThreeColumns from '../../layouts/LayoutThreeColumns/LayoutThreeColumns';
import SignUpForm from '../../containers/SignUpForm/SignUpForm';


export const SignUp = () => {

    const Middle = () => {
        return(
            <div className='signUp-middle-container'>
                <h1 className='signUp-header-h1'>Join the Culinary Community!</h1>
                <SignUpForm/>
            </div>
        )
    }

    return(
            <LayoutThreeColumns>
            {{
                left: <div/>,
                middle: <Middle/>,
                right: <div/>
            }}
            </LayoutThreeColumns>
    )
}