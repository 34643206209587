import { useEffect, useState } from "react";
import { userStore } from "../../stores/UserStore";
import { fetchFollowingWidget } from "../../service/UserService";
import { observer } from "mobx-react-lite";
import FollowingWidget from "../../components/FollowingWidget/FollowingWidget";
//import FollowingWidget from "../../components/FollowingWidget/FollowingWidget";


interface WidgetObject {
    _id: string;
    name: string;
    profilePicture: string;
}

/* interface FollowingWidgetObject {
    listOfUsers: Array<WidgetObject>;
}
 */

const UserRightSide = () => {

    const [followingWidget, setFollowingWidget] = useState<WidgetObject[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log(userStore.id)
                const followingObject = await fetchFollowingWidget(10, userStore.id);
                const data = await followingObject.json();
                console.log("Data: ", data)
                setFollowingWidget(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [userStore.id]);

    return(
        <div>
            <FollowingWidget listOfUsers={followingWidget}/> 
        </div>
    )
}
export default observer(UserRightSide);