import './SearchUserCard.css'
//import defaultProfilePicture from '../../resources/default_profilepicture.png'
import {addUserToFollow} from "../../service/UserService";
import {myStore} from "../../stores/MyStore";
import { IoPersonAdd } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';


type Props = {
    id: string
    image: string
    name: string
}

export const SearchUserCard = ({id, image, name}: Props) => {

    const navigate = useNavigate()

    const handleAddUser = async () => {
        //TODO Handle error better
        const response = await addUserToFollow(id)

        if (response.ok){
            const data = await response.json()
            const {name, profilePicture} = await data
            myStore.addFollower(id, name, profilePicture)
            myStore.incrementFollowing()
        }
    }

    const handleSeeUserProfile = (id: string) => (event: React.MouseEvent) => {
        event.preventDefault()
        console.log(id)
        navigate(`/user/${id}`);
//        navigate(`/user`);

    }

    return(
        <div className="search-user-card-container" onClick={(event) => handleSeeUserProfile(id)(event)}>
            <img className="search-user-card-image" src={image} alt=""/>
            <div className="search-user-card-name-and-title-container">
                <div className="search-user-card-name-container">
                    <p className="search-user-card-firstname">{name}</p>
                </div>
            </div>
            <div className="search-user-card-icon-container" onClick={handleAddUser}>
                <IoPersonAdd className="search-user-card-add-icon" />
            </div>
        </div>
    )
}