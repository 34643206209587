import {myStore} from "../../stores/MyStore";
import {SmallFriendProfilePicture} from "../small-friend-profile-picture/SmallFriendProfilePicture";
import {observer} from "mobx-react-lite";
import './FollowingWidget.css'
import { useNavigate } from "react-router-dom";
/* import {fetchFollowingWidget} from "../../service/UserService";
import {useEffect} from "react"; */

interface WidgetObject {
    _id: string;
    name: string;
    profilePicture: string;
}

interface FollowingWidgetObject {
    listOfUsers: Array<WidgetObject>;
}

const FollowingWidget = (props: FollowingWidgetObject) => {

    const navigator = useNavigate()


    const handleClick = (userId: string) => {
        navigator(`/user/${userId}`);
        console.log(`Clicked user with ID: ${userId}`);    
    }
    //const {listOfUsers} = props;

    return (
        <div className="friends-container-mypage">
            <p>Following ({myStore.followingLength})</p>
            <div className="following-widget">
                {props.listOfUsers && props.listOfUsers.map(user => (
                    <div onClick={() => handleClick(user._id)} className="friend-container">
                        <SmallFriendProfilePicture
                            key={user._id}
                            profilePicture={user.profilePicture}
                            name={user.name} 
                            id={user._id}/>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default observer(FollowingWidget)