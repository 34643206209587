import './Carousel.css'

//import {items} from '../../resources/RecipeCategories'
import {ReactElement, useState } from 'react'
import {GrPrevious} from 'react-icons/gr'
import {GrNext} from 'react-icons/gr'

interface CarouselProps {
  items: ReactElement[];
  cardsOnPage: number
}

export const Carousel: React.FC<CarouselProps> = ({ items, cardsOnPage })=> {

  const [index, setIndex] = useState(0)

  const visibles = items.slice(index, index+cardsOnPage)

  const handleNext = () => {
    setIndex(prevIndex => prevIndex + 1);
  }

  const handlePrev = () => {
    setIndex(prevIndex => prevIndex - 1);
  }

    return (
      <div className='carusel-container'>
          {index !== 0 ? <GrPrevious className='recipe-category-nav-button' onClick={handlePrev}/> : <GrPrevious style={{opacity:"0", fontSize:"40px", margin: "0 14px"}}/> }
            {visibles.map(item => (item))}
          {(index !== items.length - cardsOnPage && items.length > cardsOnPage) ? <GrNext className='recipe-category-nav-button' onClick={handleNext}/> :  <GrNext style={{opacity:"0", fontSize:"40px", margin: "0 14px"}}/>}
      </div>
    )
}