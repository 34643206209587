import { useParams } from "react-router-dom"
import { LayoutTwoColumns } from "../../layouts/LayoutTwoColumns/LayoutTwoColumns"
import { RecipeFilterModule } from "../../containers/RecipeFilterModule/RecipeFilterModule"
import { useEffect, useState } from "react"
import { getRecipesFromCategory, searchRecipesByKeyword } from '../../service/RecipeService'
import { RecipeSearchCard } from "../../components/RecipeSearchCard/RecipeSearchCard"
import { RecipeSearchCardInterface } from "../../global.types"
import { nanoid } from "nanoid"
import { isExpired } from "react-jwt"
import { myStore } from "../../stores/MyStore"
import { NavBar } from "../../components/NavBar/NavBar"


export const BrowseRecipe = () => { 

    const {recipeCategory} = useParams()
    const {name} = useParams()
    const [recipes, setRecipes] = useState<RecipeSearchCardInterface[]>([]);
    console.log(name)

    useEffect(() => {
      const fetchData = async () => {
        if (recipeCategory) {
          console.log("Fetching data from category")
          try {
            const response = await getRecipesFromCategory(recipeCategory);
            if (response && response.ok) {
              const data = await response.json(); // Assuming you're working with JSON data
              setRecipes(data); // Assuming data is an array of ReactElements
            } else {
              // Handle the error case if the request was not successful
            }
          } catch (error) {
            // Handle any other errors that may occur during the request
          }
        }
        else if(name){
          console.log("Fetching data from search")
          try {
            const response = await searchRecipesByKeyword(name);
            console.log("Search response", response);
            if (response && response.ok) {
              console.log("Search response ok");
              const data = await response.json(); // Assuming you're working with JSON data
              console.log("Search response data", data);
              setRecipes(data); // Assuming data is an array of ReactElements
            } 
            else {
                console.log("No recipes found");
            }
          } catch (error) {
            // Handle any other errors that may occur during the request
          }
        }
      }
  
      fetchData();
    }, [recipeCategory, name]);

    const isTokenExpired = isExpired(myStore.accessToken)
    
    return(
        <LayoutTwoColumns navbar={isTokenExpired ? <div></div> : <NavBar/>}>
            {{
                left: <RecipeFilterModule recipeType={recipeCategory ? recipeCategory : ""}/>,
                right: recipes && recipes.length > 0 ? recipes.map(recipe => {return(<RecipeSearchCard key={nanoid()} recipe={recipe}/>)}) : <p>No recipes found</p>
            }}
        </LayoutTwoColumns>
    )
}