import {makeAutoObservable} from "mobx"

class UserStore {

    _id = "";
    _name = "";
    _title = "";
    _description = "";
    _following = [];
    _profilePicture = "";
    _posts = [];

  constructor() {
    makeAutoObservable(this)
  }

    get id() {
        return this._id;
    }
    set id(value) {
        this._id = value;
    }

    get name() {
        return this._name;
    }
    set name(value) {
        this._name = value;
    }

    get title() {
        return this._title;
    }
    set title(value) {
        this._title = value;
    }

    get description() {
        return this._description;
    }
    set description(value) {
        this._description = value;
    }

    get following() {
        return this._following;
    }
    set following(value) {
        this._following = value;
    }

    get profilePicture() {
        return this._profilePicture;
    }
    set profilePicture(value) {
        this._profilePicture = value;
    }
    
    get posts() {
        return this._posts;
    }
    set posts(value) {
        this._posts = value;
    }
}
export const userStore = new UserStore()
