import { Rate } from '../Rate/Rate'
import ProfilePicture from '../profilePicture/profilePicture';
import './RecipeCaruselCard.css'
import {AiOutlineStar} from "react-icons/ai";


type Props = {
    image: string,
    header: string,
    description: string,
    rate: number,
    profilePicture: string
}



export const RecipeCaruselCard = ({image, description, rate, profilePicture, header}: Props) => {
    
    // Function to truncate text to a specified length
    const truncateText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + " . . .";
        } else {
            return text;
        }
    };
    
        // Truncate the description to a maximum of 100 characters
    const truncatedDescription = truncateText(description, 130);
    
    console.log(truncatedDescription);
    
    return(
        <div className='recipe-carusel-card-container'>
            <img className='recipe-carusel-card-image' src={image} alt="" />
            
            <div className='recipe-carusel-card-profilePicture'>
                <ProfilePicture src={profilePicture} size={'42px'}/>
            </div>
        
            <p className='recipe-carusel-card-header'>{header}</p>     
            <div className='recipe-carusel-card-rate'>
                <Rate 
                    size=".8em"
                    rate={rate}
                    activeColor="#ffcc00"
                    notActiveColor="#4d4d4d"
                    symbol={<AiOutlineStar />} 
                    elements={5}/>
            </div>              
            <p className='recipe-carusel-card-description'>{truncatedDescription}</p>
        </div>
    )
}