import { ReactNode } from "react";
import './LayoutTwoColumns.css'
import { NavigationButtons } from "../../components/NavigationButtons/NavigationButtons";

interface LayoutProps {
    navbar?: ReactNode,
    children: {
      left?: ReactNode;
      right?: ReactNode;
    };
  }

export const LayoutTwoColumns: React.FC<LayoutProps> = ({navbar, children }) => {

    return(
        <div>
            <header>
                {navbar && navbar}
                <div className="navigation-buttons-container">
                    <NavigationButtons type={"back"} size={"2vw"}/>
                </div>
            </header>
            <main className="layout-two-columns-main-container">
                <div className="layout-two-columns-left-section">{children.left}</div>
                <div className="layout-two-columns-right-section">{children.right}</div>
            </main>
            <footer>
                {/* Footer content */}
            </footer>
        </div>
    )
}