//import MyFeed from "../../containers/MyFeed/MyFeed";
import MyInfo from "../../containers/MyInfo/MyInfo";
import FollowingAndSearchMyPage from "../../containers/following-and-search-myPage/FollowingAndSearchMyPage";
import './myPage.css'
import LayoutThreeColumns from "../../layouts/LayoutThreeColumns/LayoutThreeColumns";
import {observer} from "mobx-react-lite";
//import UserFeed from "../../containers/UserFeed/UserFeed";
import MyFeed from "../../containers/MyFeed/MyFeed";
import { NavBar } from "../../components/NavBar/NavBar";
import { isExpired } from "react-jwt";
import { myStore } from "../../stores/MyStore";

const MyPage = () => {

    const isTokenExpired = isExpired(myStore.accessToken)
    
    return(
        <LayoutThreeColumns navBar={isTokenExpired && <div></div> || <NavBar/>}>
            {{
                left: <div><MyInfo /></div>,
                middle: <div><MyFeed /></div>,
                right: <div><FollowingAndSearchMyPage /></div>,
            }}
        </LayoutThreeColumns>
    )
}
export default observer(MyPage)
