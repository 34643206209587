export const recipeCategories = [
    {
        category: "Thai",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/thaifood_smallcard.png" : "http://joecthomsen.dk:8080/images/thaifood_smallcard.png"
    },
    {
        category: "Indian",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/IndianFood.png" : "http://joecthomsen.dk:8080/images/IndianFood.png"
    },
    {
        category: "Scandinavian",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/scandinavian.png" : "http://joecthomsen.dk:8080/images/scandinavian.png"
    },
    {
        category: "Italian",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/italian.png" : "http://joecthomsen.dk:8080/images/italian.png"
    },
    {
        category: "Mexican",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/mexican.png" : "http://joecthomsen.dk:8080/images/mexican.png"
    },
    {
        category: "Chinese",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/chinese.png" : "http://joecthomsen.dk:8080/images/chinese.png"
    },
    {
        category: "Japanese",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/japanese.png" : "http://joecthomsen.dk:8080/images/japanese.png"
    },
    {
        category: "Middle Eastern",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/middle-eastern.png" : "http://joecthomsen.dk:8080/images/middle-eastern.png"
    },
    {
        category: "Mediterranean",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/greek.png" : "http://joecthomsen.dk:8080/images/greek.png"
    },
    {
        category: "African",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/african.png" : "http://joecthomsen.dk:8080/images/african.png"
    },
    {
        category: "American",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/american.png" : "http://joecthomsen.dk:8080/images/american.png"
    },
    {
        category: "Vegetarian",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/vegetarian.png" : "http://joecthomsen.dk:8080/images/vegetarian.png"
    },
    {
        category: "Vegan",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/vegan.png" : "http://joecthomsen.dk:8080/images/vegan.png"
    },
    {
        category: "Desserts",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/desserts.png" : "http://joecthomsen.dk:8080/images/desserts.png"
    },
    {
        category: "Breakfast",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/breakfast.png" : "http://joecthomsen.dk:8080/images/breakfast.png"
    },
    {
        category: "Snacks",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/snacks.png" : "http://joecthomsen.dk:8080/images/snacks.png"
    },
    {
        category: "Drinks",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/drinks.png" : "http://joecthomsen.dk:8080/images/drinks.png"
    },
    {
        category: "BBQ/Grilling",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/bbq.png" : "http://joecthomsen.dk:8080/images/bbq.png"
    },
    {
        category: "Seafood",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/seafood.png" : "http://joecthomsen.dk:8080/images/seafood.png"
    },
    {
        category: "Gluten-Free",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/gluten-free.png" : "http://joecthomsen.dk:8080/images/gluten-free.png"
    },
    {
        category: "Low-Carb",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/low-carb.png" : "http://joecthomsen.dk:8080/images/low-carb.png"
    },
    {
        category: "High-Protein",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/high-protein.png" : "http://joecthomsen.dk:8080/images/high-protein.png"
    },
    {
        category: "Low-Fat",
        image: process.env.NODE_ENV === "development" ? "http://localhost:8080/images/low-fat.png" : "http://joecthomsen.dk:8080/images/low-fat.png"
    },
    // Add more categories as needed
];

// Sort the recipeCategories alphabetically by the 'category' property
recipeCategories.sort((a, b) => a.category.localeCompare(b.category));