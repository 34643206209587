export let restaurantTypes = [
    "Thai",
    "Burger",
    "Fine dining",
    "Fast Food",
    "Middle East",
    "Vietnamese",
    "Chinese",
    "Japanese",
    "Sushi",
    "Vegetarian",
    "Indian",
    "Spanish",
    "Greek",
    "Mexican",
    "French",
    "Italian",
    "Pizza",
    "Steak House"
]