import { useEffect, useState } from "react"
import { getPopularRecipes } from "../../service/RecipeService"
import { Recipe } from "../../global.types";
import { RecipeCaruselCard } from "../../components/RecipeCaruselCard/RecipeCaruselCard";
import { Carousel } from "../../components/Carousel/Carousel";
import './PopularRecipeModule.css'
/* import { nanoid } from "nanoid";
import { RecipeCarouselCategoryCard } from "../../components/RecipeCarouselCategoryCard/RecipeCarouselCategoryCard";
import { recipeCategories } from "../../resources/RecipeCategories";
 */
export const PopularRecipeModule = () => {

    const [recipe, setRecipe] = useState<Recipe[]>([]);  

    useEffect(() => {
        const fetch = async () => {
            const response = await getPopularRecipes()
            if(response){
                const data = await response.json()
                setRecipe(data)
            }           
        }
        fetch()
    },[])

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
    const handleResize = () => {
        setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
        });
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Detach the event listener on component unmount
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []); // Empty dependency array ensures that the effect runs only once on mount



    console.log("Recipe ", recipe)

    const recipeCards = recipe.map(recipe => {
        return(
            <div style={{margin:"0 32px"}}>
                <RecipeCaruselCard 
                    image={recipe.content.headerImages[0]} 
                    header={recipe.content.header} 
                    description={recipe.content.description} 
                    rate={recipe.rating} 
                    profilePicture={recipe.authorDetailes?.profilePicture ? recipe.authorDetailes.profilePicture : ''} 
                />
            </div>
        )
    })

    return(
        <div className="review-browsing-module-container">
            <h1 className="review-browsing-module-header">Popular Recipes</h1>
            <div className="review-browsing-module-carusel-container">
            {(windowSize.width >= 2500 ) &&
                <Carousel items={recipeCards} cardsOnPage={7}/>            }
            {(windowSize.width < 2500 && windowSize.width >= 2200 ) &&
                <Carousel items={recipeCards} cardsOnPage={6}/>            }
            {(windowSize.width < 2200 && windowSize.width >= 1900 ) &&
                <Carousel items={recipeCards} cardsOnPage={5}/>            }
            {(windowSize.width < 1900 && windowSize.width >= 1600) &&
                <Carousel items={recipeCards} cardsOnPage={4}/>            }
            {(windowSize.width < 1600 && windowSize.width >= 1300) &&
                <Carousel items={recipeCards} cardsOnPage={3}/>            }
            {(windowSize.width < 1300 && windowSize.width >= 1000) &&
                <Carousel items={recipeCards} cardsOnPage={2}/>            }
            {(windowSize.width < 1000 && windowSize.width >= 0) &&
                <Carousel items={recipeCards} cardsOnPage={1}/>
            }
{/*                 <Carousel items={recipeCards} cardsOnPage={4}/> */}
            </div>
       </div>
    )
}