import { observer } from "mobx-react-lite"
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signUp } from "../../service/AuthenticationService";
import { myStore } from "../../stores/MyStore";
import { Button } from "../../components/Button/Button";

const SignUpForm = () => {

    const emailInput = useRef<HTMLInputElement>(null);
    const firstNameInput = useRef<HTMLInputElement>(null);
    const lastNameInput = useRef<HTMLInputElement>(null);
    const passwordInput = useRef<HTMLInputElement>(null);
    const repeatedPasswordInput = useRef<HTMLInputElement>(null);

    let error = false
    let [errorString, setErrorString] = useState("")
    const navigate = useNavigate()

    const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        myStore.email = event.target.value
    }
    const handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        myStore.firstName = event.target.value
    }
    const handleLastname = (event: React.ChangeEvent<HTMLInputElement>) => {
        myStore.lastName = event.target.value
    }
    const handlePassword= (event: React.ChangeEvent<HTMLInputElement>) => {
        myStore.password = event.target.value
    }
    const handleRepeatPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        myStore.repeatedPassword = event.target.value
    }

    const signUpHandler = async (event: React.MouseEvent) => {

        event.preventDefault()

        if(myStore.password.length < 6){
            setErrorString("Password needs to be minimum 6 characters\n")
            error = true
        }
        else if(myStore.password !== myStore.repeatedPassword){
            setErrorString("Not matching password\n")
            error = true
        }
        else if(myStore.email.length === 0){
            setErrorString("Not valid email\n")
            error = true
        }
        else if(myStore.firstName.length === 0){
            setErrorString("PLease provide your firstname")
            error = true
        }
        else if(myStore.lastName.length === 0){
            setErrorString("PLease provide your lastname")
            error = true
        }
        else{
            error = false
        }

        if(!error) {
            const response = await signUp()
                /*const response = await httpRequest(url, method, body, header)*/

            if(response.status === 201){
                const data = await response.json()
                myStore.email = data.email
                myStore.firstName = ""
                myStore.lastName = ""
                myStore.fullName = data.name
                myStore.profilePicture = data.profilePicture
                myStore.description = data.description
                myStore.title = data.title
                myStore.accessToken = data.token
                myStore.password = ""
                myStore._repeatedPassword = ""
            }
            else if(response.status === 409){
                console.log("User already exists")
                myStore.email = ""
                myStore.firstName = ""
                myStore.lastName = ""
                myStore.accessToken = ""
                myStore.password = ""
                myStore._repeatedPassword = ""
            }
            else if(response.status === 400){
                console.log("All input must be supplied")
            }
            else {
                console.log("Error!")
            }
            // Check if the ref and its current property are defined before assigning a value
            if (emailInput && emailInput.current) {
                emailInput.current.value = "";
            }
            if (firstNameInput && firstNameInput.current) {
                firstNameInput.current.value = "";
            }
            if (lastNameInput && lastNameInput.current) {
                lastNameInput.current.value = "";
            }
            if (passwordInput && passwordInput.current) {
                passwordInput.current.value = "";
            }
            if (repeatedPasswordInput && repeatedPasswordInput.current) {
                repeatedPasswordInput.current.value = "";
            }
            if(response.status === 201){
                navigate('/mypage')
            }
        }
    }

    return(
        <form className="sign-up-form-container">
            <label className="sign-up-label">Sign Up Now!</label>
            <input className="sign-up-input" onChange={handleEmail} type="text" placeholder="Email" ref={emailInput}></input>
            <input className="sign-up-input" onChange={handleFirstName} type="text" placeholder="First Name" ref={firstNameInput}></input>
            <input className="sign-up-input" onChange={handleLastname} type="text" placeholder="Last Name" ref={lastNameInput}></input>
            <input className="sign-up-input" onChange={handlePassword} type="password" placeholder="Password" ref={passwordInput}></input>
            <input className="sign-up-input" onChange={handleRepeatPassword} type="password" placeholder="Repeat Password" ref={repeatedPasswordInput}></input>
            <Button color={"#749BFF"} fontColor={"White"} width={"15vw"} margin={"2vw 0 0 0"} text={"Sign Up Now!"} callback={signUpHandler}/>                        
            <p>{errorString}</p>
        </form>
    )
}
export default observer(SignUpForm)