import {myStore} from "../stores/MyStore";
import {endpoints} from "../resources/endpoints";

export const getMyPosts = async ():Promise<Response | undefined> => {

    let headers = new Headers();
    headers.append("userId",myStore.id)
    headers.append("token",myStore.accessToken)
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    const url = endpoints["get-my-posts"]

    try{
        return await fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: headers,
        })
    }catch (e) {
        console.log(e)
        return undefined
    }
}

export const uploadUserTitleAndDescription = async (title: string, description: string) => {

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')
    headers.append("token", myStore.accessToken)

    const url = endpoints["upload-user-title-and-description"]

    return await fetch(url, {
        headers: headers,
        method: 'PUT',
        mode: 'cors',
        body: JSON.stringify( {
            title: title,
            description: description,
        }),
    })
}

export const searchForUser = async (user: string) => {

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')
    headers.append('search', user)

    const url = endpoints["search-for-user"]

    return await fetch(url, {
        headers: headers,
        method: 'GET',
        mode: 'cors',
    })
}

export const addUserToFollow = async (userId: string) => {

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')
    headers.append('token', myStore.accessToken)

    const url = endpoints["add-user-to-follow"]

    return await fetch(url, {
        headers: headers,
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify( {
            userId: userId
        }),
    })
}

export const getFeed = async () => {

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')
    headers.append('token', myStore.accessToken)
    headers.append('page_size',"5")
    headers.append('page_number','1')

    const url = endpoints["get-feed"]

    return await fetch(url, {
        headers: headers,
        method: 'GET',
        mode: 'cors',
    })
}

export const fetchFollowingWidget = async (amount: number, userId: string) => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'text/plain')
/*     headers.append('token', myStore.accessToken)
    headers.append('size', amount.toString()) */

    console.log("Fetching following widget for user: " + userId)

    const url = endpoints["get-following-widget"].replace('{userId}', userId).replace('{size}', amount.toString());;

    return await fetch(url, {
        headers: headers,
        method: 'GET',
        mode: 'cors',
    })
}

export const getProfilePictureFromUserId = async (userId: string) => {

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'text/plain')

    const endpoint = endpoints["get-profile-picture-from-id"]
    const url = endpoint.replace('{userId}', userId);

    return await fetch(url, {
        headers: headers,
        method: 'GET',
        mode: 'cors',
    })
}

export const getUserFromId = async (userId: string) => {
    const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');

  const url = endpoints["get-user-from-id"].replace('{userId}', userId);

  return await fetch(url, {
    headers: headers,
    method: 'GET',
    mode: 'cors'
  });
}


