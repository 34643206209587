import {observer} from "mobx-react-lite";
import {recipeStore} from "../../../stores/RecipeStore";
import './IngredientChecker.css'
import {MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox} from 'react-icons/md'
import React, {CSSProperties} from "react";

const IngredientsChecker = () => {

    const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {

        const targetId = (event.target as HTMLDivElement).id;
        if (targetId) {
        }
        recipeStore.ingredients.forEach((element) => {
            if (element.id.toString() === targetId) {
                recipeStore.toggleSelected(element.id)
            }
        })
    }

    const checkboxStyle: CSSProperties = {
        "fontSize":"25px",
        "pointerEvents": "auto"
    }


    return(
        <div className="ingredient-to-check-container">
            <h1 className="ingredient-to-check-header">Ingredient Checklist</h1>
            {recipeStore.ingredients.filter(element => element.ingredient).map((ingredient, index) => {
                return(
                    <div className="ingredient-container">
                        <p style={ingredient.selected ? {"textDecoration":"line-through", "color":"gray"} : {}} className="ingredient-to-check" >{ingredient.ingredient}</p>
                        <div id={index.toString()} onClick={handleOnClick} className="ingredient-to-check-checkbox">
                            { ingredient.selected ? <MdOutlineCheckBox style={checkboxStyle} id={index.toString()}/> : <MdOutlineCheckBoxOutlineBlank style={checkboxStyle} id={index.toString()}/> }
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
export default observer(IngredientsChecker)