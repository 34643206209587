import './LayoutThreeColumns.css'
import { ReactNode } from 'react';

interface LayoutProps {
    navBar?: ReactNode,
    children: {
      left?: ReactNode;
      middle?: ReactNode;
      right?: ReactNode;
    };
  }

  const LayoutThreeColumns: React.FC<LayoutProps> = ({navBar, children }) => {

    return (
        <div>
            <header>
                {navBar && navBar}
            </header>
            <main className="layout-main-container">
                <div className="left-section">{children.left}</div>
                <div className="middle-section">{children.middle}</div>
                <div className="right-section">{children.right}</div>
            </main>
        </div>
    );
};

export default LayoutThreeColumns;