export const endpoints =
    process.env.NODE_ENV === "development"
        ?
        {
            "sign-in": "http://localhost:8080/sign-in",
            "sign-up":"http://localhost:8080/sign-up",
            "post-review":"http://localhost:8080/auth/review",
            "get-my-posts":"http://localhost:8080/auth/posts",
            "editReview":"http://localhost:8080/auth/edit/review",
            "upload-profile-picture":"http://localhost:8080/auth/upload/profilePicture",
            "upload-user-title-and-description":"http://localhost:8080/auth/title-and-description",
            "search-for-user":"http://localhost:8080/user/search",
            "add-user-to-follow":"http://localhost:8080/auth/add-follower",
            "get-feed":"http://localhost:8080/auth/feed",
            "upload-review-pictures":"http://localhost:8080/auth/upload/reviewImage",
            "get-following-widget":"http://localhost:8080/following-widget/user/{userId}/size/{size}",
            "post-recipe":"http://localhost:8080/auth/recipe",
            "edit-recipe":"http://localhost:8080/auth/edit/recipe",
            "delete-review":"http://localhost:8080/auth/delete/review",
            "delete-recipe":"http://localhost:8080/auth/recipe",
            "get-recipes-from-category":"http://localhost:8080/recipes/category/{category}",   
            "vote":"http://localhost:8080/auth/vote",
            "get-profile-picture-from-id":"http://localhost:8080/user/{userId}/profilePictureAndName",
            "get-recipe-from-recipe-id-when-not-signed-in":"http://localhost:8080/recipe/id/{recipeId}",
            "get-recipe-from-recipe-id-when-signed-in":"http://localhost:8080/auth/recipe/id/{recipeId}",
            "get-user-from-id":"http://localhost:8080/user/profile/{userId}",
            "get-newest-recipes":"http://localhost:8080/recipes/newest",
            "seach-recipes-by-keyword":"http://localhost:8080/recipes/search?keyword={keyword}",
            "get-popular-recipes":"http://localhost:8080/recipes/popular",
        }
        :
        {
            "sign-in": "http://joecthomsen.dk:8080/sign-in",
            "sign-up":"http://joecthomsen.dk:8080/sign-up",
            "post-review":"http://joecthomsen.dk:8080/auth/review",
            "get-my-posts":"http://joecthomsen.dk:8080/auth/posts",
            "editReview":"http://joecthomsen.dk:8080/auth/edit/review",
            "upload-profile-picture":"http://joecthomsen.dk:8080/auth/upload/profilePicture",
            "upload-user-title-and-description":"http://joecthomsen.dk:8080/auth/title-and-description",
            "search-for-user":"http://joecthomsen.dk:8080/user/search",
            "add-user-to-follow":"http://joecthomsen.dk:8080/auth/add-follower",
            "get-feed":"http://joecthomsen.dk:8080/auth/feed",
            "upload-review-pictures":"http://joecthomsen.dk:8080/auth/upload/reviewImage",
            "get-following-widget":"http://joecthomsen.dk:8080/following-widget/user/{userId}/size/{size}",
            "post-recipe":"http://joecthomsen.dk:8080/auth/recipe",
            "edit-recipe":"http://joecthomsen.dk:8080/auth/edit/recipe",
            "delete-review":"http://joecthomsen.dk:8080/auth/delete/review",
            "delete-recipe":"http://joecthomsen.dk:8080/auth/recipe",
            "get-recipes-from-category":"http://joecthomsen.dk:8080/recipes/category/{category}",   
            "vote":"http://joecthomsen.dk:8080/auth/vote",
            "get-profile-picture-from-id":"http://joecthomsen.dk:8080/user/{userId}/profilePictureAndName",
            "get-recipe-from-recipe-id-when-not-signed-in":"http://joecthomsen.dk:8080/recipe/id/{recipeId}",
            "get-recipe-from-recipe-id-when-signed-in":"http://joecthomsen.dk:8080/auth/recipe/id/{recipeId}",
            "get-user-from-id":"http://joecthomsen.dk:8080/user/profile/{userId}",
            "get-newest-recipes":"http://joecthomsen.dk:8080/recipes/newest",
            "seach-recipes-by-keyword":"http://joecthomsen.dk:8080/recipes/search?keyword={keyword}",
            "get-popular-recipes":"http://joecthomsen.dk:8080/recipes/popular",
        }
