import {myStore} from "../stores/MyStore";
import {useNavigate} from "react-router-dom";
//import './landingpage.css'
import {observer} from "mobx-react-lite";
import React, {useRef, useState} from "react";
import {signIn} from "../service/AuthenticationService";
import {decodeToken} from "react-jwt";


export const LandingPage = observer( () => {

    const emailInput = useRef<HTMLInputElement>(null);
    const passwordInput = useRef<HTMLInputElement>(null);
    const navigate = useNavigate()
    const [error, setError] = useState(false)
    const [errorMessage, setErrormessage] = useState("")

    const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        myStore.email = event.target.value
    }
    const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        myStore.password = event.target.value
    }

    const loginHandler = async (event: React.MouseEvent) => {
        event.preventDefault()

        const response = await signIn()
        
        if (response.ok) {
            setError(false)
            if(emailInput && emailInput.current){
                emailInput.current.value = ""
            }
            if(passwordInput && passwordInput.current) {
                passwordInput.current.value = ""
            }

            const responseData = await response.json()
            const {email, name, profilePicture, token, title, description} = await responseData

            const userToken = decodeToken(responseData.token)

            myStore.id = (userToken as { user_id: string }).user_id;
            myStore.email = email
            myStore.fullName = name
            myStore.profilePicture = profilePicture
            myStore.title = title
            myStore.description = description
            myStore.accessToken = token

            navigate('/mypage')
            myStore.storeUserStoreInLocalStorage();
        }
        else if(response.status === 401){
            setErrormessage("Invalid username or password!")
            setError(true)
        }
        else{
            myStore.email = ""
            myStore.password = ""
            if(emailInput && emailInput.current) {
                emailInput.current.value = ""
            }
            if(passwordInput && passwordInput.current) {
                passwordInput.current.value = ""
            }
        }
    }

    const signUp = (event: React.MouseEvent) => {
        event.preventDefault()
        navigate('/signup')
    }
    //Clear all cookies
    localStorage.clear()

    return(
        <div className="landingpage-container">
            <h1 className="landingpage-header">Food Rater</h1>
            <h2 className="landingpage-subheader">Discover the Taste Adventure, unleash Your Inner Food Critic!</h2>
            <form className="sign-in-form-container">
                <label className="sign-in-label">Sign In Now!</label>
                <input className="sign-in-input" onChange={handleEmail} type="text" placeholder="Email" ref={emailInput}></input>
                <input className="sign-in-input" onChange={handlePassword} type="password" placeholder="Password" ref={passwordInput}></input>
                {error ? <p className="error-message">{errorMessage}</p> : <div></div>}
                <button className="login-button" onClick={loginHandler}>Sign In!</button>
                <p className="not-signed-in-text">Not signed up yet?</p>
                <p onClick={signUp} className="sign-up-button">Sign Up!</p>
            </form>
        </div>
    )
})
//export default observer(LandingPage)