import { recipeStore } from "../../stores/RecipeStore"
import { myStore } from "../../stores/MyStore"
import './RecipeModule.css'
import { Divider } from "../../components/Divider/Divider"
import { useEffect, useState } from "react"
import {GrNext, GrPrevious} from 'react-icons/gr'
import { IconButton } from "../../components/icon-button/IconButton"
import { Recipe, Review } from "../../global.types"
import { toJS } from "mobx"
import { useNavigate } from "react-router-dom"
import NewRater from "../../components/NewRater/NewRater"
import { IoStar } from "react-icons/io5";
import { IoMdStarOutline } from "react-icons/io";
import { GiConfirmed } from "react-icons/gi";
import { vote } from "../../service/VoteService"
import { Rate } from "../../components/Rate/Rate"
import { observer } from "mobx-react-lite"
import { CiEdit } from "react-icons/ci";
import { isExpired } from "react-jwt"

const RecipeModule = () => {

    const [page, setPage] = useState(0)
    const [myRecipe, setMyRecipe] = useState(false) //This variable will determin if the current recipe belongs to the logged in user
    const [showConfirm, setShowConfirm] = useState(false)
    const [rater, setRater] = useState(0);
    const pages = recipeStore.steps.length

    const handleNextStep = () => {
        setPage(current => current + 1); // Return the updated state value
    };

    const handlePrevStep = () => {
        setPage(current => current - 1); // Return the updated state value
    };

    useEffect(() => {
        if(recipeStore.author === myStore._id){    // If the recipe author matches the logged in user id, then set the myRecipe state variable to true
            setMyRecipe(true)
        }
    },[])

    function isRecipe(post: Recipe | Review): post is Recipe {
    return (post as Recipe).author !== undefined && (post as Recipe).createdAt !== undefined;
} 

const navigator = useNavigate()

const editCallback = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log(toJS(recipeStore))
    event.preventDefault()
    const post = myStore.posts.find(post => post._id === recipeStore._id)

    if (post) {
        if(isRecipe(post)) {
            recipeStore.edit = true;
            navigator("/addRecipe");
        }
    } else {
        console.error("Post not found");
    }
}
    const handleClick = (element: number) => {
        console.log("Rating element " + element + " clicked!");
        setShowConfirm(true)
        setRater(element)
    }

    const handleConfirm = async () => {
        const response = await vote(rater, recipeStore.id)
        if(response.ok){
            const data = await response.json()
            console.log(data)
            recipeStore.myVote = rater
        }
    }

    const isTokenExpired = isExpired(myStore.accessToken)


    console.log(myRecipe)
    // !recipeStore.myVote 

    return(
        <div className="recipe-module-container">
            {myRecipe && <div className="edit-recipe-button-container"> <IconButton icon={<CiEdit/>} callback={editCallback} id={""} styles={{fontSize:"36px"}} text={"Edit Recipe"}/> </div> }
            {!myRecipe
                && 
                    <div className="recipe-module-rater-container">
                        {recipeStore.myVote === 0 
                            ?
                            <div>
                                <label className="rate-this-recipe-label" htmlFor="">Rate this recipe</label>
                                <div className="rater-and-confirm">
                                    <NewRater activeSymbol={<IoStar/>} notActiveSymbol={<IoMdStarOutline/>} elements={5} onClicks={handleClick} size="2vw" notActiveColor={"grey"} activeColor={"#F1C644"}/> 
                                    {showConfirm && <div className="confirm-and-label-container">
                                        <IconButton icon={<GiConfirmed/>} callback={handleConfirm} id={""} styles={{margin:"0 0 0 2vw", fontSize:"1.5vw", color:"#F1C644"}} text={""}/>
                                        <label className="confirm-label" htmlFor="">Confirm</label>
                                    </div>
                                    } 
                                </div>
                            </div>
                            :
                            !isTokenExpired
                            ?
                            <div className="recipe-module-rater-container">
                                <label className="rate-this-recipe-label" htmlFor="">Your Vote</label>
                                <div className="rater-and-confirm">
                                    <Rate symbol={<IoStar />} elements={5} size="2vw" notActiveColor={"grey"} activeColor={"#F1C644"} rate={recipeStore.myVote}/>  
                                </div>
                            </div>
                            :
                            <div></div>                            
                            }
                        
                    </div> 
                
      
            }
            
            <div className="step-container">
                <div>
                    <h2 className="step-header">Step {page+1} - {recipeStore.steps[page].header}</h2>
                    <p className="recipe-step-description">{recipeStore.steps[page].stepDescription}</p>
                </div>
                <Divider color={"lightblue"} margin={"30px 0 0 0"}/>
            </div>
            <div className="recipe-label-and-module-step-navigation-container">
                <div className="recipe-module-step-navigation-container">
                    {page !== 0 ? <IconButton icon={<GrPrevious/>}callback={handlePrevStep} id={""}text={""} styles={{fontSize:"3vh", opacity:"0.5"}}/> : <IconButton icon={<GrPrevious/>}callback={() => {}} id={""}text={""} styles={{fontSize:"3vh", opacity:"0.2"}}/> }
                    <p>{page+1}/{pages}</p>
                    {page !== pages - 1 ? <IconButton icon={<GrNext/>}callback={handleNextStep} id={""}text={""} styles={{fontSize:"3vh", opacity:"0.5"}}/> : <IconButton icon={<GrNext/>}callback={() => {}} id={""}text={""} styles={{fontSize:"3vh", opacity:"0.2"}}/>}
                </div>
            </div>
        </div>
    )
}
export default observer(RecipeModule)
