import './PageOne.css'
import {recipeStore} from "../../../stores/RecipeStore";
import {observer} from "mobx-react-lite";
import {NavigationButtons} from "../../NavigationButtons/NavigationButtons";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {BiImageAdd} from "react-icons/bi";
import ImageSelector from "../../../hooks/ImageSelector/ImageSelector";
import {Button} from "../../Button/Button";
import {deleteRecipe} from "../../../service/RecipeService";
import {myStore} from "../../../stores/MyStore";
import React from 'react'
import {GrAdd} from 'react-icons/gr'
import {IconButton} from "../../icon-button/IconButton";
import {nanoid} from "nanoid";
import { recipeCategories } from '../../../resources/RecipeCategories';

const PageOne = () => {

    const [error, setError] = useState(false)
    let errorTwo = false
    const [errorMsg, setErrorMsg] = useState("")

    const navigate = useNavigate()

    const handleHeader = (event: React.ChangeEvent<HTMLInputElement>) => {
        recipeStore.addHeader(event.target.value);
    }
    const handleDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        recipeStore.addDescription(event.target.value);
    }
    const handlePersons = (event: React.ChangeEvent<HTMLSelectElement>) => {
        recipeStore.addPersons(event.target.value);
    }
    const handleDifficulty= (event: React.ChangeEvent<HTMLSelectElement>) => {
        recipeStore.addDifficulty(event.target.value)
    }
    const handleCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
        recipeStore.addCategory(event.target.value)
    }
    const handleIngredient = (event: React.ChangeEvent<HTMLInputElement>) => {
        recipeStore.addIngredientItem(event.target.value, parseInt(event.target.id))
    }
    const handleUnit = (event: React.ChangeEvent<HTMLInputElement>) => {
        recipeStore.addUnit(event.target.value, parseInt(event.target.id))
    }

    const callbackNavigationBack = () => {
        //recipeStore.clearStore()
        navigate(-1)
    }

    const handleNext = () => {
        setErrorMsg("Please fill in the following input:\n")
        if (recipeStore._header === undefined) {
            errorTwo = true
            setErrorMsg(prevState => {
                return (prevState + "\nheader, ")
            })
        }
        if (recipeStore._description === undefined) {
            errorTwo = true
            setErrorMsg(prevState => {
                return (prevState + "\ndescription, ")
            })
        }
        if (recipeStore._persons === undefined) {
            errorTwo = true
            setErrorMsg(prevState => {
                return (prevState + "\npersons, ")
            })
        }
        if(recipeStore._difficulty === undefined){
            errorTwo = true
            setErrorMsg(prevState => {
                return (prevState + "\ndifficulty level,")
            })
        }
        if (recipeStore._ingredients.filter(element => element.ingredient !== "").length === 0) {
            errorTwo = true
            setErrorMsg(prevState => {
                return (prevState + "\n\nAt least one ingredient is required!")
            })
        }
        if (!errorTwo) {
            recipeStore.setPageOne();
        }
        else( setError(true))
    }

    const handleHeaderImage = (image: string[]) => {
        recipeStore.headerImage = image
    }

    const handleDeleteRecipe = async (event: React.MouseEvent) => {
        event.preventDefault()
        navigate('/mypage')
        myStore.deleteUserPost(recipeStore.id)
        await deleteRecipe()
        navigate('/mypage')
        //TODO Error handling

    }

    return(
        <form className="post-recipe-form-container">
            <div className="post-recipe-form-container-one-nav-buttons">
                <NavigationButtons type="back" size="2vw" callbackBack={callbackNavigationBack}/>
            </div>
            <label>Create Recipe</label>
            <input className="add-recipe-input-field" onChange={handleHeader} type="text" placeholder="Header" value={recipeStore.header}/>
            <input className="add-recipe-input-field" onChange={handleDescription} type="text" placeholder="Description" value={recipeStore.description}/>
{/*             <input className="add-recipe-input-field" onChange={handlePersons} type="text" placeholder="Persons" value={recipeStore.persons}/>
 */}        
            <select onChange={handlePersons} className="drop-down-menu" value={recipeStore.persons}>
                <option value="" disabled={true}>Select Persons</option>
                {Array.from({ length: 12 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                    {index + 1}
                    </option>
                ))}
            </select>
            <select onChange={handleDifficulty} className="drop-down-menu" value={recipeStore.difficulty}>
                <option value="" disabled={true}>Select Difficulty Level</option>
                <option>Beginner-Friendly</option>
                <option>Intermediate</option>
                <option>Challenging</option>
                <option>Advanced</option>
                <option>Expert</option>
            </select>
            <select name="recipe-category-selector" id="" className="drop-down-menu" onChange={handleCategory} value={recipeStore.category}>
                <option value="" disabled={true}>Select Category</option>
                {recipeCategories.map(element => {
                    return(
                        <option selected={element.category === recipeStore.category} value={element.category}>{element.category}</option>
                    )
                })}
            </select>

            <div className="post-recipe-ingredients-container">
                {recipeStore.ingredients.map((element, index) => {
                    return(
                        <div key={index} className="ingredient-container">
                            <input onChange={handleIngredient} id={index.toString()} className="ingredient" type="text" placeholder="Ingredient" value={element.ingredient}/>
                            <input onChange={handleUnit} id={index.toString()} className="unit" type="text" placeholder="Unit" value={element.unit}/>
                        </div>
                    )
                })}
            </div>
            <IconButton icon={<GrAdd/>} callback={recipeStore.addIngredient} id={nanoid()} styles={{"fontSize":"2vw", "margin":"24px 0 0 0"}} text={""}/>

            <div className="image-selector-page-one">
                <ImageSelector callback={handleHeaderImage} icon={<BiImageAdd className="post-review-image-selector"/>} multiple={false}/>
                <p className="image-selector-text">Add header image</p>
            </div>
            {recipeStore.headerImage.length > 0 ? <img src={recipeStore.headerImage[0]}  alt=""/> : <div></div>}
            {recipeStore.edit ? <Button color="red" fontColor="white" callback={handleDeleteRecipe} text="Delete Recipe" width="100%" margin="16px 0 0 0"/> : <div></div>}
            <p onClick={handleNext} className="post-recipe-next-button-container">NEXT</p>
            {error ? <p className="recipe-error-message">{errorMsg}</p> : <div></div>}
        </form>
    )
}
export default observer(PageOne)
