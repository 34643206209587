import { observer } from "mobx-react-lite"
import { userStore } from "../../stores/UserStore"
import { nanoid } from "nanoid"
import { PostCard } from "../../components/PostCard/PostCard"

const UserFeed = () => {
    return(
        <div>
                {userStore.posts.length > 0
                ?
                <div>
                    {userStore.posts.map((element) => {
                        return(
                            <div key={nanoid()}>
                                <PostCard element={element}/>
                            </div>
                        )
                    })}
                </div>
                :
                <p className="no-post-yet-text" >{userStore.name} has not created any posts yet</p>
            }
        </div>
    )
}
export default observer(UserFeed)