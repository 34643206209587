import { observer } from "mobx-react-lite";
import { userStore } from "../../stores/UserStore";
import ProfilePicture from "../../components/profilePicture/profilePicture";
import './UserInfo.css';

const UserInfo = () => {
    return(
    <div className="user-info-container">
        <div className="profile-picture-and-rank-container">
            <ProfilePicture src={userStore.profilePicture} size={"10vw"} />                   
        </div>
        <h2 className="name">{userStore.name}</h2>
        <h3 className="user-title">{userStore.title}</h3>
        <p className="user-description">{userStore.description}</p>
    </div>
    )
}
export default observer( UserInfo);