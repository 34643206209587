import '../my_page/myPage.css'
import PostReview from "../../components/post-review/PostReview";
import LayoutThreeColumns from "../../layouts/LayoutThreeColumns/LayoutThreeColumns";
import MyInfo from "../../containers/MyInfo/MyInfo";
import FollowingAndSearchMyPage from "../../containers/following-and-search-myPage/FollowingAndSearchMyPage";

export const AddRestaurantReview = () => {
    return(
        <LayoutThreeColumns>
            {{
                left: <MyInfo/>,
                middle: <PostReview/>,
                right: <FollowingAndSearchMyPage/>
            }}
        </LayoutThreeColumns>
    )
}