import {observer} from "mobx-react-lite";
import {recipeStore} from "../../../stores/RecipeStore";
import './PageTwo.css'
import {IconButton} from "../../icon-button/IconButton";
import {MdAddTask, MdOutlinePlaylistAdd, MdDeleteOutline} from 'react-icons/md'
import {GrUpdate}  from 'react-icons/gr'
import {NavigationButtons} from "../../NavigationButtons/NavigationButtons";
import {editRecipe, postRecipe} from "../../../service/RecipeService";
import {myStore} from "../../../stores/MyStore";
import {useNavigate} from "react-router-dom";
import ImageSelector from "../../../hooks/ImageSelector/ImageSelector";
import {BiImageAdd} from "react-icons/bi";
import React from "react";
import {nanoid} from "nanoid";


const PageTwo = () => {

    const navigate = useNavigate()

    const handleHeader = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        recipeStore.setStepHeader(index, event.target.value)
    }
    const handleDescription = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
        recipeStore.setStepDescription(index, event.target.value)
    }

    const iconButtonSize = {
        "fontSize":"2rem"
    }
    const handleAddStep = () => {
        recipeStore.addStep();
    }
    const handleBackButton = () => {
        recipeStore.setPageOne();
    }
    const handleDeleteStep = (index: number) => {
        if(recipeStore.steps.length === 1){
            return;
        }
        recipeStore.deleteStep(index);
    }

    const handlePost = async () => {

        try {
            const response = await postRecipe();

            //recipeStore.setPageOne()
            if(response.ok) {
                const data = await response.json()
                myStore.recipes.unshift(data);
                myStore.posts.unshift(data)
                recipeStore.clearStore()
                navigate('/mypage', {replace: true})
            }
        }catch (e) {
           console.error(e)
        }
    }

    const handleEdit = async () => {
        try {
            const response = await editRecipe();
            console.log(response)
            recipeStore.setPageOne()
            if(response && response.ok) {
                const data = await response.json();

                const indexToUpdate = myStore.recipes.findIndex(recipe => recipe._id === data._id);
                if (indexToUpdate !== -1) {
                    // Replace the recipe at the same index with the updated data
                    myStore.recipes[indexToUpdate] = data;
                }

                const indexToUpdatePosts = myStore.posts.findIndex(recipe => recipe._id === data._id);
                if (indexToUpdatePosts !== -1) {
                    // Replace the recipe at the same index with the updated data
                    myStore.posts[indexToUpdatePosts] = data;
                }
                recipeStore.clearStore()
                navigate('/mypage')
            }
        }catch (e) {
            console.error(e)
        }
    }

    return(
        <form className="post-recipe-form-container-two">
            <div className="post-recipe-form-container-two-nav-buttons">
                <NavigationButtons type="back" size="2vw" callbackBack={handleBackButton}/>
            </div>
            <label>Create Recipe</label>
            {recipeStore.steps.map((step, index) => {
                const handleImage = (image: string[], stepIndex: number) => {
                    recipeStore.addStepImages(image, stepIndex)
                }

                const stepId = index.toString(); // Convert index to string for unique IDs
                return(
                    <div key={index} className="step-container">
                        <div className="step-header-and-delete-button">
                            <h3 className="step-header">Step {index+1}</h3>
                            <div className="step-delete-button" onClick={() => handleDeleteStep(index)}>
                                <MdDeleteOutline/>
                            </div>
                            <div className="add-image-to-step">
                                <ImageSelector callback={(image: string[]) => handleImage(image, index)} icon={<BiImageAdd className="step-image-selector"/>} multiple={true}/>
                            </div>
                        </div>
                        <div className="step-image-container">
                            {step.stepImages.length && step.stepImages.map((image: string) => {return(
                                <img className="step-image" src={image} alt=""/>
                            )})}
                        </div>
                        <input id={`description-${stepId}`} onChange={(event) => handleHeader(event, index)} type="text" placeholder="Step header (Optional)" value={step.header}/>
                        <textarea id={`header-${stepId}`} onChange={(event) => handleDescription(event, index)} className="step-description-textarea" placeholder="Add description for this step" value={step.stepDescription}/>
                    </div>
                )
            })}
            <div className="add-recipe-icon-button-container">
                <IconButton id={nanoid()} icon={<MdOutlinePlaylistAdd/>} text="Add Step" callback={handleAddStep} styles={iconButtonSize}/>
                {recipeStore.edit
                    ?
                    <IconButton id={nanoid()} icon={<GrUpdate/>} text="Update" callback={handleEdit} styles={iconButtonSize}/>
                    :
                    <IconButton id={nanoid()} icon={<MdAddTask/>} text="Post" callback={handlePost} styles={iconButtonSize}/>
                }

            </div>
        </form>
    )
}
export default observer(PageTwo)