import {ReactNode} from "react"

type Props = {
    activeColor: string
    notActiveColor: string
    size: string
    elements: number
    symbol: ReactNode
    rate: number
}



export const Rate = ({activeColor, notActiveColor, size, elements, symbol, rate}: Props) => {

    let rateArray = []

    for (let i = 0 ; i < elements ; i++){
        rateArray.push(symbol)
    }

    const activeStyle = {
        "color":activeColor,
        "fontSize":size
    }
    const notActiveStyle = {
        "color":notActiveColor,
        "fontSize":size
    }

    if(rate === undefined){
        rate = 0;
    }

    return(
        <div>
            {rateArray.map((element: ReactNode, index: number) => {return(
                <span
                    style={index >= rate ? notActiveStyle : activeStyle}
                    key={index}>{element}
                </span>
            )})}
        </div>
    )
}