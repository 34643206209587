import {myStore} from "../stores/MyStore";
import {endpoints} from "../resources/endpoints";
import {reviewStore} from "../stores/ReviewStore";

function blobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                const base64String = (reader.result as string).split(',')[1];
                resolve(base64String);
            } else {
                // Handle the case where reader.result is null
                reject(new Error("Failed to read the file."));
            }
        };
        reader.onerror = error => {
            reject(error);
        };
        reader.readAsDataURL(blob);
    });
}

function fetchBlobFromURL(blobURL: string) {
    return fetch(blobURL)
        .then(response => response.blob())
        .catch(error => {
            console.error("Error fetching Blob:", error);
            return null;
        });
}

export const postReview = async () => {

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')
    headers.append('token', myStore.accessToken)
    headers.append("post_type",'review')

    const base64Image = await Promise.all(
        reviewStore.images.map(async image => {
            const blob = await fetchBlobFromURL(image)
            if(blob){
                return await blobToBase64(blob)
            }
            else{
                return null;
            }
        })
    );
    

    const url = endpoints["post-review"]
    const body = {
        restaurantName: reviewStore.restaurantName,
        address: reviewStore.address,
        country: reviewStore.country,
        city: reviewStore.city,
        type: reviewStore.type,
        rating: reviewStore.rating,
        priceRange: reviewStore.priceRange,
        title: reviewStore.header,
        review: reviewStore.review,
        images: base64Image ? base64Image : []
    }

    try {
        const response = await fetch(url, {
            headers: headers,
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(body)
        })
        return response
    }catch (e){
        console.error(e)
        throw e; // Propagate the error by rethrowing it
    }
}

export const editReview = async () => {

    const url = endpoints["editReview"]//"http://localhost:8080/auth/reviews"

    let headers = new Headers();
    headers.append("post_id",reviewStore.id)
    headers.append("token",myStore.accessToken)
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    const body = {
    restaurantName:reviewStore.restaurantName,
    address:reviewStore.address,
    country:reviewStore.country,
    city:reviewStore.city,
    type:reviewStore.type,
    rating:reviewStore.rating,
    priceRange:reviewStore.priceRange,
    title:reviewStore.header,
    review:reviewStore.review
    }

    try{
        return await fetch(url, {
            headers: headers,
            mode: 'cors',
            method: 'PUT',
            body: JSON.stringify(body)
        })

    }catch (e) {
        console.error(e)
        throw e; // Propagate the error by rethrowing it
    }
}

export const deleteReview = async () => {

    const url = endpoints["delete-review"]

    let headers = new Headers();
    headers.append("post_id",reviewStore.id)
    headers.append("token",myStore.accessToken)
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    try{
        return await fetch(url, {
            headers: headers,
            mode: 'cors',
            method: 'PUT',
        })

    }catch (e) {
        console.error(e)
        throw e; // Propagate the error by rethrowing it
    }
}