import {makeAutoObservable, observable, runInAction, toJS} from "mobx"

interface RecipeStoreInterface {

    _id: string,
    author: string,
    createdAt: string,
    postType: string[],
    myVote: number,
    rating: number,
    content: {
        id: string;
        header: string;
        description: string;
        persons: string;
        difficulty: string;
        category: string,
        ingredients: Array<{ id: number; ingredient: string; unit: string; selected: boolean }>;
        steps: Array<{ header: string; stepDescription: string; stepImages: string[] }>;
        pageOne: boolean;
        activeStep: number;
        edit: boolean;
        headerImages: string[];
        rating: number;
        votes: number;
        name: string
        /* profilePicture?: string */
        myVote?:number;
    }
}

class RecipeStore {

    _id: string = "";
    _author: string = ""
    _createdAt: string = ""
    _header: string = "";
    _description: string = "";
    _persons: string = "";
    _difficulty: string = "";
    _category: string = "";
    _ingredients: Array<{ id: number; ingredient: string; unit: string; selected: boolean }> = [];
    _steps: Array<{ header: string; stepDescription: string; stepImages: string[] }> = [];
    _pageOne: boolean = true;
    _activeStep: number = 0;
    _edit: boolean = false;
    _headerImage: string[] = [];
    _rating: number = 0;
    _votes: number = 0;
    _myVote: number = 0;


    constructor() {
        makeAutoObservable(this,
            {},
            {autoBind: true}//For non-arrow-functions bind
        )
        for (let i = 0; i < 6 ; i++) {
            const ingredient = observable({"id":i,"ingredient": "", "unit": "" ,"selected":false});
            this.ingredients.push(ingredient);
        }
        this.steps.push({"header":"","stepDescription":"", "stepImages":[]})

    }

    populateStore(post: RecipeStoreInterface) {



        const {header, description, persons, headerImages, difficulty, votes, category} = post.content
        const {author, createdAt, myVote, rating} = post;

        console.log("Rating: ", rating)
        this.id = post._id
        this.header = header
        this.category = category
        this.description = description
        this.persons = persons
        this.difficulty = difficulty
        this.votes = votes
        this.rating = rating
        this.author = author
        this.createdAt = createdAt
        this.headerImage = headerImages 
        this.ingredients = post.content.ingredients.map(element => ({ ...element }));
        this.steps = post.content.steps.map(element => ({ ...element }));
        this.myVote = myVote;

/*         steps.forEach((element, index) => {
            this.steps[index] = element
        }) */

    }

    clearStore() {
        runInAction(() => {

        this.id = "";
        this.author = "";
        this.createdAt = "";
        this.header = "";
        this.description = "";
        this.persons = "";
        this.difficulty = "";

        this.ingredients.forEach(ingredient => {
            ingredient.ingredient = "";
            ingredient.unit = "";
            ingredient.selected = false;
        });

        this.steps = [{"header":"","stepDescription":"", "stepImages":[]}]

        this.steps.forEach(step => {
            step.header = "";
            step.stepDescription = "";
        });

        this.pageOne = true;
        this.activeStep = 0;
        this.edit = false;
        this.headerImage = []
        })
        this.rating = 0;
        this.votes = 0;
    }

    toJSON() {
        return {
            id: this._id,
            header: this.header,
            description: this.description,
            persons: this.persons,
            difficulty: this.difficulty,
            ingredients: toJS(this._ingredients), // Convert observable array to plain JS array
            steps: toJS(this._steps), // Convert observable array to plain JS array
            pageOne: this._pageOne,
            activeStep: this.activeStep,
            edit: this._edit,
            headerImages: this.headerImage,
            rating: this._rating,
            votes: this._votes,
            category: this.category
        };
    }

    addIngredient(){
        const newIngredient = {"id":this.ingredients.length, "ingredient": "", "unit": "","selected":false};

        // Wrap the modification in a MobX action
        // This ensures that MobX tracks changes to object properties inside the array
        this.ingredients.push(newIngredient);
    }

    addStepImages(images: string[], index: number){
        this.steps[index].stepImages = images
    }

    addHeader(header: string){
        this.header = header;
    }
    addDescription(description: string){
        this.description = description;
    }
    addPersons(persons: string){
        this.persons = persons;
    }
    addDifficulty(difficulty: string){
        this.difficulty = difficulty;
    }
    addCategory(category: string){
        this.category = category
    }
    addIngredientItem(ingredient: string, index: number){
        const ingredientToEdit = this.ingredients[index];
        ingredientToEdit.ingredient = ingredient;
    }
    addUnit(unit: string, index: number){
        const ingredientToEdit = this.ingredients[index];
        ingredientToEdit.unit = unit;
    }
    setPageOne(){
        this.pageOne = !this.pageOne
    }
    getPageOne(){
        return this.pageOne
    }
    getIngredient(index: number){
        return this.ingredients[index].ingredient
    }
    getUnit(index: number){
        return this.ingredients[index].unit
    }
    setStepHeader(index: number, header: string){
/*        const headerToEdit = this.steps[index];
        headerToEdit.header = header*/
        runInAction(() => {
            this.steps[index].header = header;
        })
    }
    setStepDescription(index: number, description: string){
/*      
        const headerToEdit = this.steps[index];
        headerToEdit.stepDescription = stepDescription*/
        runInAction(() => {
            this.steps[index].stepDescription = description;
        })

    }
    addStep(){
        this.steps.push({"header":"","stepDescription":"", "stepImages":[]})
    }
    setActiveStep(index: number){
        this.activeStep = index
    }
    toggleSelected(index: number){
        const elementToEdit = this.ingredients[index]
        elementToEdit.selected = !elementToEdit.selected;
    }
    deleteStep(index: number){
        runInAction(() => {
            this.steps.splice(index, 1)
        })
    }

    get author() {
        return this._author;
    }

    set author(value) {
        this._author = value;
    }

    get createdAt() {
        return this._createdAt;
    }

    set createdAt(value) {
        this._createdAt = value;
    }

    get header() {
        return this._header;
    }

    set header(value) {
        this._header = value;
    }

    get description() {
        return this._description;
    }

    set description(value) {
        this._description = value;
    }

    get persons() {
        return this._persons;
    }

    set persons(value) {
        this._persons = value;
    }

    get difficulty() {
        return this._difficulty;
    }

    set difficulty(value) {
        this._difficulty = value;
    }

    get category() {
        return this._category;
    }

    set category(value) {
        this._category = value;
    }

    get ingredients() {
        return this._ingredients;
    }

    set ingredients(value) {
        this._ingredients = value;
    }

    get steps() {
        return this._steps;
    }

    set steps(value) {
        this._steps = value;
    }

    get pageOne() {
        return this._pageOne;
    }

    set pageOne(value) {
        this._pageOne = value;
    }

    get activeStep() {
        return this._activeStep;
    }

    set activeStep(value) {
        this._activeStep = value;
    }
    get edit() {
        return this._edit;
    }

    set edit(value) {
        this._edit = value;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }


    get headerImage() {
        return this._headerImage;
    }

    set headerImage(value) {
        this._headerImage = value;
    }


    get rating() {
        return this._rating;
    }

    set rating(value) {
        this._rating = value;
    }

    get votes() {
        return this._votes;
    }

    set votes(value) {
        this._votes = value;
    }

    get myVote() {
        return this._myVote;
    }

    set myVote(value) {
        this._myVote = value;
    }
}
export const recipeStore = new RecipeStore()