import { useNavigate } from 'react-router-dom'
import './RecipeCarouselCategoryCard.css'

type Props = {
    text: string,
    image: string
}

export const RecipeCarouselCategoryCard = ({text, image}: Props) => {

    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/recipes/search/category/${text}`)
    }


    return(
        <div className="recipe-category-card-wrapper" key={text} onClick={handleClick}>
            <div className="recipe-category-card-container">
                <img src={image} alt="" />
                <h1 className='recipe-category-card-text'>{text}</h1>
            </div>
        </div>
    )
}