import './App.css';
import {Routes, Route, BrowserRouter as Router} from "react-router-dom";
//import {PrivateRoutes} from "./components/PrivateRoutes";
import  {Browsing} from './view/browse/Browsing'
import {LandingPage} from './view/LandingPage'
import MyPage from "./view/my_page/MyPage";
import {Settings} from "./view/Settings";
import {AddRestaurantReview} from "./view/add-review/AddRestaurantReview";
import {SignUp} from "./view/sign-up/SignUp";
import 'react-tooltip/dist/react-tooltip.css'
import AddRecipe from "./view/add-recipe/AddRecipe";
import { RecipeView } from './view/recipe/RecipeView';
import { BrowseTwo } from './view/BrowseTwo/BrowseTwo';
import { BrowseRecipe } from './view/BrowseRecipe/BrowseRecipe';
import UserView from './view/UserView/UserView';
import LandingpageNew from './view/LandingpageNew/LandingpageNew';

function App() {
  return (
      <Router>
        <Routes>
{/*           <Route element={<PrivateRoutes children={undefined}/>}>

          </Route> */}
          <Route path='/abc' element={<LandingPage/> }/>
          <Route path='/' element={<LandingpageNew/>}/>
          <Route path='/mypage' element={<MyPage/>}/>
          <Route path='/user/:userId' element={<UserView/>}/>
          <Route path='/settings' element={<Settings/>}/>
          <Route path='/addReview' element={<AddRestaurantReview/>}/>
          <Route path='/addRecipe' element={<AddRecipe/>}/>
          <Route path='/browse' element={<Browsing/>}/>
          <Route path='/signup' element={<SignUp/>}/>
          <Route path='/recipe' element={<RecipeView/>}/>
          <Route path='/browseTwo' element={<BrowseTwo/>}/>
          <Route path='/recipes/search/category/:recipeCategory' element={  <BrowseRecipe/>} />
          <Route path='/recipes/search/name/:name' element={ <BrowseRecipe/>}/>
        </Routes>
      </Router>
  );
}

export default App;
