import {ReactNode} from "react";
import {useState} from "react";
import './rater.css'


type Props = {
    activeColor: string,
    notActiveColor: string,
    size: string,
    elements: number
    callback?: (rate: number) => void
    symbol: ReactNode
}

export const Rater = ({activeColor, notActiveColor, size, elements, callback, symbol}: Props) => {


    let rater = []

    for (let i = 0 ; i < elements ; i++){
        rater.push(symbol)
    }

    //const [hoverState, setHoverState] = useState(0)
    const [rating, setRating] = useState(0)
    const [inited, setInited] = useState(false)

    const stylesActive = {
        "color": activeColor,
        "fontSize":size
    }

    const stylesNotActive = {
        "color":notActiveColor,
        "fontSize":size
    }

    const styleNotInit = {
        "color":notActiveColor,
        "fontSize":size
    }

    const handleClick = (rate: number) => {
        if(!inited){
            setInited(true)
        }
        setRating(rate)
        if(callback != null) {
            callback(rate)
        }
        else{
            defaultCallback()
        }
    }
    const defaultCallback = () => {
        console.log("Implement a callback function in rater")
    }

    return(
        <div className="rater-container">
            {rater.map((element, index) => {
                return(
                    <div key={index}
                         style={!inited ? styleNotInit : index > rating ? stylesNotActive : stylesActive}
                         onClick={() => {handleClick(index)}}>
                        {element}
                    </div>
                )
            })}
        </div>
    )
}


