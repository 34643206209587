import {NavLink, useNavigate} from "react-router-dom";
import './navbar.css'
/* import { IconButton } from "../icon-button/IconButton";
import { nanoid } from "nanoid"; */
import { TbLogout } from "react-icons/tb";
import { feed } from "../../stores/FeedStore";
import { myStore } from "../../stores/MyStore";
import { recipeStore } from "../../stores/RecipeStore";
import { reviewStore } from "../../stores/ReviewStore";
import { IoIosNotifications } from "react-icons/io";
import { MdOutlineSettings } from "react-icons/md";
import { RiMenuSearchLine } from "react-icons/ri";
import ProfilePicture from "../profilePicture/profilePicture";


export const NavBar = () => {

    const navigate = useNavigate()
    const handleSignOut = () => {
        localStorage.clear();
        myStore.clear()
        feed.clearFeed()
        recipeStore.clearStore()
        reviewStore.clearStore()
        navigate('/',{replace: true})
    }

    return(
        <nav className="navbar-container">
            <NavLink className={({isActive}) => isActive ? "active-class": "non-active-class" } to='/browseTwo'><RiMenuSearchLine/></NavLink>
            <NavLink className={({isActive}) => isActive ? "active-class": "non-active-class" } to='/settings'><MdOutlineSettings/></NavLink>
            <NavLink className={({isActive}) => isActive ? "active-class": "non-active-class" } to='/'>{<IoIosNotifications/>}</NavLink>
            <NavLink className={({isActive}) => isActive ? "active-class": "non-active-class" } to='/myPage'><ProfilePicture src={myStore.profilePicture} size={"28px"} borderWidth="1px"/></NavLink>
            <NavLink className={({isActive}) => isActive ? "active-class": "non-active-class" } to='/' onClick={handleSignOut}><TbLogout/></NavLink>
            
{/*             <div className="sign-out-icon-button">
                <IconButton id={nanoid()} icon={<TbLogout/>} text="" styles={{"marginLeft":"100%", "fontSize":"32px"}} callback={handleSignOut}/>
            </div> */}
        </nav>
    )
}