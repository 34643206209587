import { RecipeSearchCardInterface } from "../../global.types"
import { Rate } from "../Rate/Rate"
import {MdStarRate} from 'react-icons/md'
import './RecipeSearchCard.css'
import { recipeStore } from "../../stores/RecipeStore"
import { useNavigate } from "react-router-dom"
import { getRecipeFromRecipeId } from "../../service/RecipeService"



export const RecipeSearchCard = ({ recipe }: { recipe: RecipeSearchCardInterface }) => {

    const navigator = useNavigate()
    const recipeId = recipe._id

    const handleSeeRecipe = async () => {
        const response = await getRecipeFromRecipeId(recipeId)
        if(response.ok){
            const data = await response.json()
            recipeStore.populateStore(data)
            navigator('/recipe')
        }
        else{
            console.error("Could not fetch recipe or popultate store")
            navigator('/mypage')
        }
    }
    
    return(
        <div className="recipe-browse-card-container" onClick={handleSeeRecipe}>
            <img className="recipe-browse-img" src={recipe.content.headerImages[0]} alt="" />
            <div className="recipe-browse-rate-h1-p-container">
                <div className="recipe-browse-rate-h1">
                    <h1 className="recipe-browse-h1">{recipe.content.header}</h1>
                    <div className="recipe-browse-rate-container">
                        <Rate activeColor={"#F1C644"} notActiveColor={"#D4D4D4"} size={"1vw"} elements={5} symbol={<MdStarRate/>} rate={recipe.rating}/> 
                    </div>
                </div> 
                <p className="recipe-browse-description">{recipe.content.description}</p>
            </div>
        </div>
    )    
}