import LayoutThreeColumns from "../layouts/LayoutThreeColumns/LayoutThreeColumns";

export const Settings = () => {
    return(
        <LayoutThreeColumns>
            {{
                left: <div></div>,
                middle: <div>Settings</div>,
                right: <div></div>
            }}    
        </LayoutThreeColumns>
    )
}