import ReviewCards from "./reviewCard/ReviewCard";
import RecipeCard from "./recipeCard/RecipeCard";
import { Recipe, Review } from '../../global.types';

type Props = {
    element: Review | Recipe,
}

export const PostCard = ({element}: Props) => {

    const {postType} = element
    const trimmedPostType = postType.toString().trim()


    switch (trimmedPostType){
        case 'Review': {
            return(
                <ReviewCards reviewElement={element as Review}/>
            )
        }
        case 'Recipe':{
            return(
                <RecipeCard recipe={element as Recipe}/>
            )
        }
        default:{
            console.error("error fetching postType " + postType)
            return null
        }
    }
}