import ProfilePicture from "../../components/profilePicture/profilePicture";
import {myStore} from "../../stores/MyStore";
import {EditTextButton} from "../../hooks/edit-text-button/EditTextButton";
import React, {useState} from "react";
import {uploadUserTitleAndDescription} from "../../service/UserService";
import {ImCancelCircle} from 'react-icons/im'
import {AiOutlineCheckCircle} from 'react-icons/ai'
import './MyInfo.css'
import UploadImageButton from "../../hooks/upload_image_button/UploadImageButton";
import { endpoints } from "../../resources/endpoints";
import { observer } from "mobx-react-lite";


const MyInfo = () => {

    const [isEditing, setIsEditing] = useState(false)
    const [title, setTitle] = useState(myStore.title)
    const [description, setDescription] = useState(myStore.description)

    let backup = myStore.profilePicture

    const startEdit = () => {
        setIsEditing((prevState) => {return(!prevState)})
    }

    const cancelEdit = () => {
        setTitle(myStore.title)
        setDescription(myStore.description)
        setIsEditing(false)
    }

    const confirmChanges = async () => {
        myStore.setUserTitle(title)
        myStore.setUserDescription(description)
        await uploadUserTitleAndDescription(myStore.title, myStore.description)
        //TODO make use of the response and catch error
        setIsEditing(false)
    }

    const handleEditTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value)
    }
    const handleEditDescription =  (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value)
    }

    const showPreview = (image: string) => {
        //user.profilePicture = image
        //backup = user.profilePicture
        //user.setProfilePicture(image)
        myStore.profilePicture = image
    }

    const cancel = () => {
        myStore.setProfilePicture(backup)
    }

    return(
        <div>
            <div className="my-page-left">
                <div className="profile-picture-and-rank-container">
                    <ProfilePicture src={myStore.profilePicture} size={"10vw"} />
                    <div className="my-page-profile-picture-container">
                         <UploadImageButton selectSymbol="photo_camera" cancelCallback={cancel} previewCallback={showPreview} url={endpoints["upload-profile-picture"]}/>
                    </div>                    
                </div>
                <h2 className="name">{myStore.fullName}</h2>
                {isEditing
                    ?
                    <div>
                        <form>
                            <div className="user-title-container-with-edit-button">
                                <input onChange={handleEditTitle} type="text" className="user-title-edit-mode" value={title}></input>
                                <div className="edit-text-button-container-my-page">
                                    <div className="edit-button" onClick={cancelEdit}>
                                        <ImCancelCircle/>
                                    </div>
                                    <div className="edit-button" onClick={confirmChanges}>
                                        <AiOutlineCheckCircle/>
                                    </div>
                                </div>
                            </div>
                            <textarea onChange={handleEditDescription} className="user-description-edit-mode" value={description}/>
                        </form>
                    </div>
                    :
                    <div>
                        <div className="user-title-container-with-edit-button">
                            <h3 className="user-title">{title}</h3>
                            <div className="edit-text-button-container-my-page">
                                <EditTextButton callback={startEdit} size="1.9rem" color="black"/>
                            </div>
                        </div>
                        <p className="user-description">{description}</p>
                    </div>
                }
            </div>
        </div>
    )
}
export default observer(MyInfo)