
import './RecipeCategoryModule.css'
import { Carousel } from "../../components/Carousel/Carousel"
import { recipeCategories } from '../../resources/RecipeCategories'
import { RecipeCarouselCategoryCard } from '../../components/RecipeCarouselCategoryCard/RecipeCarouselCategoryCard'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const RecipeCategoryModule = () => {

    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate()

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
    const handleResize = () => {
        setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
        });
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Detach the event listener on component unmount
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []); // Empty dependency array ensures that the effect runs only once on mount




    const handleKeyPress = (event: { key: string }) => {
        if (event.key === 'Enter') {
            // Call your search function with the entered value
            searchRecipes(searchValue);
        }
    };

    const handleInputChange = (event: { target: { value: any } }) => {
        setSearchValue(event.target.value);
    };

    const searchRecipes = (keyword: any) => {
        // Call API or search logic
        console.log('Searching for:', keyword);
        navigate('/recipes/search/name/'+keyword);
        setSearchValue('')
        
    };


    return(
        <div className='recipe-category-container'>
            <div className='header-and-search-input-container'>
                { windowSize.width > 1199 && <h1 className="recipe-category-header">Recipes</h1>}
                <input 
                    className='recipe-category-input' 
                    type="text"
                    value={searchValue} 
                    placeholder='Search Recipe'
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                />
            </div>
            {(windowSize.width >= 1599 ) &&
                <Carousel items={recipeCategories.map(item => (<RecipeCarouselCategoryCard key={nanoid()} text={item.category} image={item.image} />))} cardsOnPage={7}/>
            }
            {(windowSize.width < 1599 && windowSize.width >= 1400 ) &&
                <Carousel items={recipeCategories.map(item => (<RecipeCarouselCategoryCard key={nanoid()} text={item.category} image={item.image} />))} cardsOnPage={6}/>
            }
            {(windowSize.width < 1400 && windowSize.width >= 1199 ) &&
                <Carousel items={recipeCategories.map(item => (<RecipeCarouselCategoryCard key={nanoid()} text={item.category} image={item.image} />))} cardsOnPage={5}/>
            }
            {(windowSize.width < 1199 && windowSize.width >= 1000) &&
                <Carousel items={recipeCategories.map(item => (<RecipeCarouselCategoryCard key={nanoid()} text={item.category} image={item.image} />))} cardsOnPage={4}/>
            }
            {(windowSize.width < 1000 && windowSize.width >= 800) &&
                <Carousel items={recipeCategories.map(item => (<RecipeCarouselCategoryCard key={nanoid()} text={item.category} image={item.image} />))} cardsOnPage={3}/>
            }
            {(windowSize.width < 800 && windowSize.width >= 600) &&
                <Carousel items={recipeCategories.map(item => (<RecipeCarouselCategoryCard key={nanoid()} text={item.category} image={item.image} />))} cardsOnPage={2}/>
            }
            {(windowSize.width < 600 && windowSize.width >= 0) &&
                <Carousel items={recipeCategories.map(item => (<RecipeCarouselCategoryCard key={nanoid()} text={item.category} image={item.image} />))} cardsOnPage={1}/>
            }
        </div>
    )
}
